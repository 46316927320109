import classNames from "classnames";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DietProfilePath } from ".";
import { CurrentUser } from "../../controllers/CurrentUser";
import DateUtil from "../../utils/DateUtil";
import { Testimonials } from "../root/testimonials";
import DietProfileHeader from "./DietProfileHeader";
import "./bcm-offerpage.css";

const DietProfileResults = () => {
  const offersRef = React.useRef<HTMLDivElement>(null);
  const formRef = React.useRef<HTMLFormElement>(null);

  const floatingButton = React.useRef<HTMLDivElement>(null);
  const [selectedOffer, setSelectedOffer] = useState(0);
  const [monthsOffer, setMonthsOffer] = useState(0);
  const [paymentType, setPaymentType] = useState(0);
  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState(CurrentUser.profile.email);
  const [emailConfirm, setEmailConfirm] = useState("");
  const [isAdult, setIsAdult] = useState(false);
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);

  const navigate = useNavigate();

  useEffect(() => {
    if (!CurrentUser.hasDietProfileResults) {
      navigate(DietProfilePath.Start);
    }
  }, [navigate]);

  useEffect(() => {
    const handleScroll = () => {
      if (floatingButton.current && offersRef.current && window.scrollY >= offersRef.current.offsetTop - 100) {
        floatingButton.current.classList.add("fadeOut");
      } else if (floatingButton.current) {
        floatingButton.current.classList.remove("fadeOut");
      }
    };

    window.addEventListener("scroll", handleScroll);
    if (CurrentUser.targetWeightloss < 3) {
      setMonthsOffer(1);
      setSelectedOffer(1);
      // } else if (CurrentUser.targetWeightloss < 10) {
      //   setMonthsOffer(3);
      //   setSelectedOffer(3);
    } else {
      setMonthsOffer(3);
      setSelectedOffer(3);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const bmiLeft = (bmi: number) => {
    //const left = 100 - 100 * ((bmi - 18.3) / 21);
    if (bmi < 19) {
      return 100 - bmi * 0.9;
    } else if (bmi < 25) {
      return 55 - (bmi - 25) * 3.5;
    } else if (bmi < 30) {
      return 30 - (bmi - 30) * 3.5;
    } else {
      return 5 - (bmi - 39) * 2;
    }
  };

  const submitHandler = () => {
    if (paymentType === 0 || !email || !firstname || email !== emailConfirm || !isAdult) {
      setIsValid(false);
      return;
    }
    setIsValid(true);
    formRef?.current?.submit();
  };

  return (
    <>
      <DietProfileHeader />

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="results-wrapper">
                <div className="bcm-results-intro">
                  <div className="bcm-results-intro-image">
                    <img
                      src="/dietprofile-images/bcm-dp-jmcround.png"
                      alt="Dr Jean-Michel COHEN"
                      className="img-fluid"
                    />
                  </div>
                  <div className="bcm-results-intro-text">
                    <h4 className="mb-3">
                      <strong>Voici vos résultats, {CurrentUser.profile.firstName}</strong>
                    </h4>
                    <p>
                      Découvrez l'évaluation que nous pouvons faire d’après les informations que vous nous avez
                      fournies.
                    </p>
                    <p>
                      Vous voulez perdre <strong>{CurrentUser.targetWeightloss}</strong>kg, nous pouvons vous y faire
                      parvenir en <strong>{CurrentUser.weightlossDuration}</strong> mois.
                    </p>
                  </div>
                </div>

                <div className="bcm-results-graphs">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="results-well" style={{ minHeight: "94%" }}>
                        {/* <!-- <p className="text-center">"Vous avez les <strong>moyens d'obtenir, rapidement</strong>, le <strong>corps</strong> que vous <strong>souhaitez.</strong>"</p>--> */}
                        <div className="weightgraph-illustration graph-curve">
                          <img
                            src={`/dietprofile-images/bcm-results-graph-${CurrentUser.genderName}.jpg`}
                            alt="weightgraph"
                            className="img-fluid"
                          />
                          <div className="labels start-content">
                            {DateUtil.format(new Date())} <br />
                            <strong>{CurrentUser.profile.initialWeight}&nbsp;kilos</strong>
                          </div>
                          <div className="labels end-content">
                            {DateUtil.format(DateUtil.addDays(CurrentUser.weightlossDuration * 30))}
                            <br />
                            <strong>{CurrentUser.profile.targetWeight}&nbsp;kilos</strong>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="results-well misc-data">
                        <div className="rw-icon">
                          <i className="fas fa-weight" style={{ color: "#fa2c40" }}></i>
                        </div>
                        <div>
                          <p>Après 1 mois </p>
                          <h5>Entre -3 et -5kg </h5>
                        </div>
                      </div>

                      <div className="results-well misc-data">
                        <div className="rw-icon">
                          <i className="fas fa-fire" style={{ color: "#f9922d" }}></i>
                        </div>
                        <div>
                          <p>Niveau calorique</p>
                          <h5>{CurrentUser.profile.gender === 0 ? `1600` : `1800`}</h5>
                        </div>
                      </div>

                      <div className="results-well misc-data">
                        <div className="rw-icon">
                          <i className="fas fa-eye" style={{ color: "#3ad843" }}></i>
                        </div>
                        <div>
                          <p>Impact</p>
                          <h5>Changement visible dès la 1ère semaine</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <br />

                <div className="bcm-bmi-bar">
                  <div>
                    <p className="text-center">
                      <span>{CurrentUser.profile.firstName}</span>, vous avez <strong>{CurrentUser.profile.age}</strong>{" "}
                      ans et vous mesurez <strong>{CurrentUser.profile.height}</strong> cm pour{" "}
                      <strong>{CurrentUser.profile.initialWeight}</strong> kilos. Votre IMC aujourd’hui&nbsp;:
                    </p>

                    <div className="bmi-scale">
                      <div>
                        <img
                          src="https://img.aujourdhui.com/batchcookingcohen/dietprofile/mrp-bmi-bar.png"
                          alt=""
                          className="img-fluid"
                        />
                        <div className="bmi-bubble" style={{ left: `${bmiLeft(CurrentUser.BMI)}%` }}>
                          {CurrentUser.BMI}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div>
                <h3 className="emerald">
                  <strong>Concernant votre alimentation actuelle&nbsp;:</strong>
                </h3>
                {/* <!--<h4 className="salmon"><strong>1. Je grignote très souvent</strong></h4>--> */}
                {CurrentUser.profile.q5 === 1 ? (
                  <>
                    <p>
                      Bon d'accord, vous aimez bien grignoter entre les repas et ça vous fait parfois culpabiliser.
                      Pourtant, il n’y a pas de quoi car si vous aimez grignoter, il faut le faire : la frustration
                      conduit souvent à l'échec de votre perte de poids.
                    </p>
                    <p>
                      Avec un peu d’organisation, vous pouvez prévoir des petits encas simples et savoureux pour prendre
                      du plaisir sans aucune conséquence.
                    </p>
                    <p>
                      Il y a les classiques du grignotage sain (rondelles de concombres, dés de pommes, tomates-cerises,
                      yaourts nature), mais aussi des aliments plus originaux (tout aussi sains) qui vous apporteront un
                      peu de fantaisie : cornichons, tzatziki, galettes de riz, mini-bretzels, etc.
                    </p>
                    <p>
                      Ce sont de petites astuces comme celles-ci qui vont vous aider à acquérir des habitudes plus
                      compatibles avec votre objectif de perte de poids.
                    </p>
                  </>
                ) : CurrentUser.profile.q5 === 2 ? (
                  <>
                    <p>
                      Bon d'accord, vous aimez manger et parfois vous exagérez un peu. Ce n’est pas grave : vous n’allez
                      pas commencer à vous priver de nourriture pour perdre du poids. La frustration pendant un régime
                      conduit très souvent à l'échec.
                    </p>
                    <p>
                      Ce que je vous invite à tester, ce sont les multiples recettes et préparations qui vont vous
                      permettre de manger beaucoup (en quantité), mais sans excès de calories. Et rassurez-vous, il n’y
                      a pas que des légumes au programme !
                    </p>
                    <p>
                      Pour manger un peu moins, vous pouvez aussi utiliser des assiettes plus petites (surtout si vous
                      avez le syndrome de "l'assiette propre"). Mais nous avons tout prévu pour que vous n’ayez pas de
                      frustration sur les quantités : suivez le guide !
                    </p>
                  </>
                ) : CurrentUser.profile.q5 === 3 ? (
                  <>
                    <p>
                      Bon d'accord, vous mangez souvent à l’extérieur et ce n’est pas toujours facile de contrôler ce
                      que vous mangez. Sauf si on apprend à faire les bons choix... Et ça n’est pas si difficile.
                    </p>
                    <p>
                      Par exemple, le principal danger au restaurant ne se situe pas dans les entrées et les plats car
                      vous pouvez facilement en évaluer la nature diététique (ou pas). Le risque est surtout dans les
                      sauces, les assaisonnements et les produits "conviviaux", ceux que l'on consomme sans s'en rendre
                      compte comme les cacahuètes, le pain et les biscuits apéritifs.
                    </p>
                    <p>
                      Un bon conseil consiste à se limiter à l'eau pendant les repas ou, si l’on cède au plaisir d'un ou
                      deux verres de vin, de faire l'impasse sur le dessert. Avec le batch cooking, ces soucis sont très
                      largement évités : vous emportez vos plats avec vous. Si ce n’est pas possible, suivre les simples
                      conseils déjà donnés peuvent changer la donne !
                    </p>
                  </>
                ) : CurrentUser.profile.q5 === 4 ? (
                  <>
                    <p>
                      Bon d'accord, vous adorez les recettes riches hautement caloriques. Et vous ne comptez
                      certainement pas les bannir de votre assiette pour éviter toute frustration.
                    </p>
                    <p>
                      Vous avez raison, car se priver conduit généralement à l'échec. Ce que je vous invite à tester, ce
                      sont les multiples astuces qui vont limiter la part des graisses et du sucre dans vos
                      préparations.
                    </p>
                    <p>
                      Par exemple, nous allons apporter un soin particulier aux modes de cuisson pour concilier plaisir
                      et santé : nous pourrons choisir le grill, le four, la vapeur ou la cuisson à l'étouffée selon la
                      nature des plats. Ce sont de petites astuces comme celle-ci, répétées quotidiennement, qui vont
                      vous aider à consommer moins de matières grasses.
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      Bon d'accord, vous ne faites pas d’excès sur le plan alimentaire mais pourtant, vous avez de
                      vraies difficultés à maigrir.
                    </p>
                    <p>
                      Votre situation personnelle mérite une attention particulière : il faut déterminer pourquoi votre
                      corps a décidé de faire de la résistance. Peut-être votre poids a-t-il atteint un palier ?
                      Peut-être manquez-vous de temps pour cuisiner ? Peut-être manquez-vous d’activités physiques ?
                    </p>
                    <p>
                      Chaque prise de poids a son histoire : en déterminer les causes aide à éviter les erreurs du passé
                      et à faire les bons choix pour l'avenir.
                    </p>
                    <p>
                      Suivez-nous sur le batch cooking et voyons ensemble les premiers résultats : il y a de fortes
                      chances pour que les premiers signes positifs apparaissent à votre grande surprise ! C’est notre
                      expertise.
                    </p>
                  </>
                )}

                <p>
                  <strong>
                    <span>{CurrentUser.profile.firstName}</span>, perdre du poids sera plus facile que vous ne
                    l’imaginez ! Relevez-vous le défi ? Lancez-vous ! 😊
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div>
                <h3 className="salmon">
                  <strong>Ce que vous obtiendrez avec le Batch Cooking Minceur :</strong>
                </h3>
                <div className="features-img d-block d-md-none">
                  <img src="/dietprofile-images/resultsmobile-phone.gif" alt="" className="img-fluid" />
                </div>
                <div className="row">
                  <div className="col-md-7">
                    <div className="mb-data">
                      <div className="rw-illustration">
                        <img
                          src="/dietprofile-images/bcm-illustration-jmc.png"
                          alt="Jean-Michel Cohen"
                          className="img-fluid mx-auto"
                        />
                      </div>
                      <div>
                        <p>
                          Vos plans repas batch cooking validés par Jean-Michel Cohen - nutritionniste spécialiste de la
                          perte de poids - et du goût !{" "}
                        </p>
                      </div>
                    </div>

                    <div className="mb-data">
                      <div className="rw-illustration">
                        <img
                          src="/dietprofile-images/bcm-illustration-recipebook.png"
                          alt=""
                          className="img-fluid mx-auto"
                        />
                      </div>
                      <div>
                        <p>
                          Une liste de courses optimisée avec 25 produits maximum à acheter en une seule fois et les
                          instructions de préparation.
                        </p>
                      </div>
                    </div>

                    <div className="mb-data">
                      <div className="rw-illustration">
                        <img
                          src="/dietprofile-images/bcm-illustration-savings.png"
                          alt=""
                          className="img-fluid mx-auto"
                        />
                      </div>
                      <div>
                        <p>Un budget alimentation contrôlé, très appréciable en période de forte inflation.</p>
                      </div>
                    </div>

                    <div className="mb-data">
                      <div className="rw-illustration">
                        <img
                          src="/dietprofile-images/bcm-illustration-teleconsultation.png"
                          alt=""
                          className="img-fluid mx-auto"
                        />
                      </div>
                      <div>
                        <p>Un atelier vidéo Batch Cooking Minceur live pour poser toutes vos questions aux experts.</p>
                      </div>
                    </div>

                    <div className="mb-data">
                      <div className="rw-illustration">
                        <img
                          src="/dietprofile-images/bcm-illustration-scale.png"
                          alt=""
                          className="img-fluid mx-auto"
                        />
                      </div>
                      <div>
                        <p>La garantie de perdre du poids à votre rythme en cuisinant vous-même.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 d-none d-md-block">
                    <div className="features-img">
                      <img
                        src="https://img.aujourdhui.com/batchcookingcohen/dietprofile/resultsmobile-phone.gif"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bcm-testimonials-section bg-gray">
        <Testimonials />
      </section>

      <section id="BcmOffers" ref={offersRef}>
        <div className="container" style={{ maxWidth: "1140px" }}>
          <div className="row">
            <div className="col-12">
              <p className="text-center">
                {CurrentUser.profile.firstName}, pour atteindre votre objectif tout en adoptant à long terme de bonnes
                habitudes alimentaires, <br className="d-none d-sm-block" />
                je vous conseille de profiter de notre offre de {monthsOffer} mois&nbsp;:
              </p>

              <br />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div>
                <form
                  name="frmPayment"
                  id="frmPayment"
                  method="post"
                  action="https://batchcooking.jeanmichelcohen.fr/bilan-minceur/resultats-personnalises.asp"
                  ref={formRef}
                >
                  <input name="action" value="buyOffer" type="hidden" />
                  <input name="v" value="0" type="hidden" />
                  <input name="quiz_log_id" value="" type="hidden" />
                  <input name="sid" value="0" type="hidden" />

                  <div className="offerSelect fltBox">
                    <h3 className="emerald">1. Je choisis ma formule </h3>
                    <div className="bcm-offers">
                      <div className="row">
                        <div className="col-lg-6 ">
                          <div
                            //className="offerBounds selected"
                            className={classNames({
                              offerBounds: true,
                              selected: selectedOffer === 1,
                              recommended: monthsOffer === 1,
                            })}
                          >
                            <h4 className="text-center">{monthsOffer === 1 && `Recommandé`}&nbsp;</h4>
                            <h2>
                              <span>9,99</span> pour 1 mois*{" "}
                            </h2>
                            <ul>
                              <li>Les menus complets de votre semaine </li>
                              <li>Les 5 plats minceur à cuisiner en 2h</li>
                              <li>La liste de courses optimisée (25&nbsp;produits max)</li>
                              <li>L'atelier vidéo Batch Cooking en direct </li>
                              <li>Les bons plans pour votre budget alimentation </li>
                            </ul>
                            <hr />
                            <label className="toggleRadio" htmlFor="bcmOffer1" onClick={() => setSelectedOffer(1)}>
                              SÉLECTIONNER
                              <input
                                type="radio"
                                name="bcmOffer"
                                value="1"
                                id="bcmOffer1"
                                checked={monthsOffer === 1}
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 ">
                          <div
                            className={classNames({
                              offerBounds: true,
                              selected: selectedOffer === 3,
                              recommended: monthsOffer === 3,
                            })}
                          >
                            <h4 className="text-center">{monthsOffer === 3 && `Recommandé`}&nbsp;</h4>
                            {/* <!--<h4>Plus de 10 kg à perdre </h4>--> */}

                            <h2>
                              <span>19,99</span> pour 3 mois*{" "}
                            </h2>
                            <ul>
                              <li>Les menus complets de votre semaine </li>
                              <li>Les 5 plats minceur à cuisiner en 2h</li>
                              <li>La liste de courses optimisée (25&nbsp;produits max)</li>
                              <li>L'atelier vidéo Batch Cooking en direct </li>
                              <li>Les bons plans pour votre budget alimentation </li>
                            </ul>
                            <hr />
                            <label className="toggleRadio" htmlFor="bcmOffer2" onClick={() => setSelectedOffer(3)}>
                              SÉLECTIONNER
                              <input
                                type="radio"
                                name="bcmOffer"
                                value="2"
                                id="bcmOffer2"
                                checked={monthsOffer === 3}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="specialBook">
                    <div>
                      <img
                        src="https://img.aujourdhui.com/batchcookingcohen/payment/book_les_milleurs_dessert.jpg"
                        alt="les_milleurs_desserts"
                        className="lmc"
                      />
                    </div>
                    <div>
                      <h4 className="salmon">
                        <strong>Bonus</strong>
                      </h4>
                      Nous vous offrons les 10 meilleurs desserts à préparer en Batch Cooking, le livre de recettes
                      simples, pratiques et gourmandes de Jean-Michel Cohen.
                    </div>
                  </div>
                  <br />
                  <div className="paymentTypeSelect fltBox">
                    <h3 className="emerald">2. Je choisis mon moyen de paiement</h3>
                    <div id="paymentSelect">
                      <div className="radio paychoice">
                        <span className="sep">
                          <label className="normal" htmlFor="ptype1">
                            <input
                              id="ptype1"
                              value="5"
                              name="payment_type"
                              className="paymentRadio"
                              type="radio"
                              onClick={() => setPaymentType(5)}
                            />{" "}
                            paiement par carte bancaire
                          </label>
                          <img
                            alt="paiement par carte bancaire"
                            style={{ position: "relative", top: "0px" }}
                            src="https://savoir-maigrir.aujourdhui.com/img/payment/paiement-cb-lock2.gif"
                          />
                        </span>
                      </div>
                      <div className="radio paychoice">
                        <span className="sep">
                          <label className="normal" htmlFor="ptype9">
                            <input
                              id="ptype9"
                              value="9"
                              name="payment_type"
                              className="paymentRadio"
                              type="radio"
                              onClick={() => setPaymentType(9)}
                            />{" "}
                            paiement SEPA (virement bancaire sécurisé){" "}
                          </label>
                        </span>
                      </div>

                      <div className="radio paychoice">
                        <span className="sep">
                          <label className="normal" htmlFor="ptype7">
                            <input
                              id="ptype7"
                              value="7"
                              name="payment_type"
                              className="paymentRadio"
                              type="radio"
                              onClick={() => setPaymentType(7)}
                            />{" "}
                            Google Pay
                          </label>
                          <img
                            alt="Google Pay"
                            style={{ position: "relative", left: "0.5px", height: "35px" }}
                            src="https://savoir-maigrir.aujourdhui.com/img_aj/jmc/payment/google-pay-mark.png"
                          />
                        </span>
                      </div>
                      <div className="radio paychoice">
                        <span className="sep">
                          <label className="normal" htmlFor="ptype4">
                            <input
                              id="ptype4"
                              value="8"
                              name="payment_type"
                              className="pBoxOff paymentRadio"
                              type="radio"
                              onClick={() => setPaymentType(8)}
                            />{" "}
                            PayPal
                          </label>
                          <img
                            alt="PayPal"
                            style={{ position: "relative", top: "0px" }}
                            src="https://savoir-maigrir.aujourdhui.com/img_aj/centralized/courbe/paypal-90.gif"
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="personalInformationInput fltBox">
                    <h3 className="emerald">3. Je renseigne mes informations</h3>
                    <div className="informationForm">
                      <div className="form-group row align-items-center">
                        <label className="col-sm-5 control-label text-left" htmlFor="prenom">
                          Mon Prénom
                        </label>
                        <div className="col-sm-7">
                          <input
                            placeholder=""
                            id="prenom"
                            className="form-control input-sm"
                            type="text"
                            name="prenom"
                            value={firstname}
                            onChange={(e) => setFirstname(e.currentTarget.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label className="col-sm-5 control-label text-left" htmlFor="email">
                          Mon Email
                        </label>
                        <div className="col-sm-7">
                          <input
                            placeholder=""
                            id="email"
                            className="form-control input-sm"
                            type="email"
                            name="emailnew"
                            // defaultValue={}
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label className="col-sm-5 control-label text-left" htmlFor="email2">
                          Je confirme mon Email
                        </label>
                        <div className="col-sm-7">
                          <input
                            placeholder=""
                            id="email2"
                            className="form-control input-sm"
                            type="email"
                            name="email_conf"
                            value={emailConfirm}
                            onChange={(e) => setEmailConfirm(e.currentTarget.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="checkbox">
                    <p>
                      <label>
                        <input
                          name="adult"
                          value="1"
                          id="charte2"
                          type="checkbox"
                          onChange={(e) => setIsAdult(e.currentTarget.checked)}
                        />{" "}
                        J'affirme être majeur(e), et avoir lu, compris et accepté les{" "}
                        <a className="greenlink" href="/contact/conditionsdutilisation.asp" target="_blank">
                          conditions générales d'utilisation
                        </a>{" "}
                        ainsi que{" "}
                        <a //onClick="text_sopen('/contact/vieprivee.asp?v=0&amp;sid=0'); return false;"
                          href="/contact/vieprivee.asp"
                          className="greenlink"
                        >
                          la charte sur le respect de la vie privée
                        </a>
                        .
                      </label>
                    </p>
                  </div>

                  {isValid === false && (
                    <ul className="pl-4 mt-2 text-align-left text-danger">
                      {!firstname && <li>Merci de préciser votre prénom</li>}
                      {!email ? (
                        <li>Merci de préciser votre email</li>
                      ) : email !== emailConfirm ? (
                        <li>Attention l'E-mail et la confirmation de votre E-mail doivent être identiques</li>
                      ) : null}
                      {!isAdult && <li>Merci de confirmer que vous avez plus de 18 ans</li>}
                      {!isAdult && <li>Merci d'accepter les conditions générales</li>}
                      {paymentType === 0 && <li>Choisissez votre solution de paiement</li>}
                    </ul>
                  )}

                  <br />

                  <div className="text-center">
                    <button
                      type="submit"
                      className="cta-salmon"
                      onClick={(e) => {
                        e.preventDefault();
                        submitHandler();
                      }}
                    >
                      JE M'INSCRIS MAINTENANT
                    </button>
                  </div>
                  <br />
                  <br />
                  <hr />
                  <div className="renewal">
                    <ul className="disclaimer2" style={{ padding: "0 0 0 12px" }}>
                      <li>À la fin de votre accès 1 mois, vous serez prélevé de 9,99 € par mois.</li>
                      <li>À la fin de votre accès 3 mois, vous serez prélevé de 6,66 € par mois.</li>
                    </ul>
                    <p className="disclaimer2">
                      Vous pouvez bien sûr à tout moment résilier ou continuer votre accès en appelant notre
                      service-client au{" "}
                      <a href="tel:+33411880112" className="und">
                        0411880112
                      </a>
                      .
                    </p>
                    <div className="disclaimer2">
                      * Prix d'un appel local depuis un poste fixe - ouvert du L au V, de 9h00 à 18h00.
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="rightcolHolder">
                <div className="payment-sidebox clearfix sor hidden-xs">
                  <h5 className="sideB">
                    Satisfait ou remboursé
                    <br />
                    sous 14 jours
                  </h5>
                  <p>
                    <img
                      alt=""
                      src="https://savoir-maigrir.aujourdhui.com/img/payment/guarantee_sticker.png"
                      width="114"
                      height="96"
                      //align="right"
                    />
                    Nous nous engageons à vous rembourser, sous 14 jours, l'intégralité de votre programme si vous
                    n'êtes pas satisfait.
                  </p>
                </div>
                <div className="payment-sidebox clearfix bda hidden-xs">
                  <h5 className="sideB" style={{ fontSize: "28px" }}>
                    Besoin d'aide ?
                  </h5>
                  <p style={{ width: "70%", textAlign: "center" }}>
                    <span style={{ lineHeight: "25px" }}>
                      <strong>Contactez nous au</strong>
                    </span>{" "}
                    <br />
                    <strong style={{ fontSize: "22px" }}>04 11 88 01 12 </strong>
                    <br />
                    <span style={{ fontSize: "10px" }}>Prix d'un appel local</span>
                    <br />
                    <strong style={{ fontSize: "12px" }}>Ouvert de 9H00 à 18h00</strong>
                  </p>
                </div>
                <div className="payment-sidebox clearfix hidden-xs">
                  <h5 className="sideB">Votre Batch Cooking Minceur également sur mobile et tablette </h5>

                  <div className="hidden-xs">
                    <p
                      //style="text-align:center;"
                      className="text-center"
                    >
                      <strong>Tous vos outils minceur à portée de main :</strong>
                    </p>
                    <div className="mobtab">
                      <ul>
                        <li>Listes de courses</li>
                        <li>Recettes</li>
                        <li>Vidéos</li>
                        <li>Plans de repas</li>
                        <li>Questions</li>
                        <li>Courbe de poids...</li>
                      </ul>
                      <img
                        alt=""
                        src="https://img.aujourdhui.com/batchcookingcohen/payment/bcm-payment-mobile.png"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <p className="text-center">
                    <strong>
                      Disponible avec votre abonnement sans frais supplémentaires pour Android, iPhone et iPad
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bottomBlack">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="text-center">
                <ul className="disclaimer">
                  <li>À la fin de votre accès 1 mois, vous serez prélevé de 9,99 € par mois.</li>
                  <li>À la fin de votre accès 3 mois, vous serez prélevé de 6,66 € par mois.</li>
                  <li>À la fin de votre accès 6 mois, vous serez prélevé de 4,99 € par mois.</li>
                </ul>
                <p className="disclaimer">
                  Vous pouvez bien sûr à tout moment résilier ou continuer votre accès en appelant notre service-client
                  au{" "}
                  <a href="tel:+33411880112" className="und">
                    0411880112
                  </a>
                  .
                </p>
                <div className="disclaimer">
                  * Prix d'un appel local depuis un poste fixe - ouvert du L au V, de 9h00 à 18h00.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FloatingButton className="floatingButton" ref={floatingButton}>
        <div className="text-center">
          <button
            type="button"
            className="cta-salmon"
            id="ViewOffer"
            onClick={() => offersRef.current?.scrollIntoView({ behavior: "smooth" })}
          >
            Voir mon offre
          </button>
        </div>
      </FloatingButton>
    </>
  );
};

export default DietProfileResults;

const FloatingButton = styled.section`
  opacity: 1;
  transition: opacity 500ms;

  /* &.fadeIn {
    opacity: 1;
  } */
  &.fadeOut {
    opacity: 0;
  }
`;
