import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { ScrollToTop } from "../App";

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <main>
      <ScrollToTop />

      <header id="BatchCookingHeader">
        <div className="container-fluid">
          <div className="headPad">
            <a href="#home" className="text-center bcm-header-logo">
              <img
                src="/images/bcm-header-logo-d.png"
                alt="Batch Cooking Minceur COHEN"
                className="img-fluid d-none d-sm-block mx-auto"
              />
              <img
                src="/images/bcm-header-logo-d.png"
                alt="Batch Cooking Minceur COHEN"
                className="img-fluid d-block d-sm-none mx-auto"
              />
            </a>

            <div className="bcm-top-menu d-none d-lg-block">
              <div className="desktopNav text-center">
                <div>
                  <NavLink to="/dr-cohen" className={({ isActive }) => (isActive ? "active" : "")}>
                    Dr&nbsp;Cohen
                  </NavLink>
                </div>
                <div>
                  <NavLink to="/batch-cooking-cest-quoi" className={({ isActive }) => (isActive ? "active" : "")}>
                    Batch Cooking&nbsp;?
                  </NavLink>
                </div>
                <div>
                  <NavLink to="/programme" className={({ isActive }) => (isActive ? "active" : "")}>
                    Programme
                  </NavLink>
                </div>
                <div>
                  <NavLink to="/weekly-guide" className={({ isActive }) => (isActive ? "active" : "")}>
                    Exemple de&nbsp;menus
                  </NavLink>
                </div>
                <div>
                  <NavLink to="/faq" className={({ isActive }) => (isActive ? "active" : "")}>
                    FAQ
                  </NavLink>
                </div>
                <div>
                  <NavLink to="/contact-info" className={({ isActive }) => (isActive ? "active" : "")}>
                    Contact
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="rightHead">
              <div className="d-none d-lg-block">
                <div className="logreg-buttons" role="group">
                  <a href="#non" className="btn inscription">
                    <span>Inscription</span>
                  </a>
                  <a href="#non" className="btn" style={{ marginLeft: "5px" }}>
                    <span>Connexion</span>
                  </a>
                </div>
                <div className="pb-2">
                  <div className="cn-line">
                    Contactez-nous au <a href="tel:+33481090430*">04&nbsp;81&nbsp;09&nbsp;04&nbsp;30*</a>
                  </div>
                </div>
              </div>

              <div className="d-block d-lg-none">
                <UncontrolledDropdown>
                  <DropdownToggle className="menuMobile">
                    <i className="fas fa-bars"></i>
                  </DropdownToggle>
                  <DropdownMenu end>
                    <Link className="dropdown-item" to="/dr-cohen">
                      Dr Cohen
                    </Link>
                    <Link className="dropdown-item" to="/batch-cooking-cest-quoi">
                      Batch Cooking ?
                    </Link>
                    <Link className="dropdown-item" to="/programme">
                      Programme
                    </Link>
                    <Link className="dropdown-item" to="/weekly-guide">
                      Exemple de menus
                    </Link>
                    <Link className="dropdown-item" to="/faq">
                      FAQ
                    </Link>
                    <Link className="dropdown-item" to="/contact-info">
                      Contact
                    </Link>
                    <DropdownItem divider />
                    <Link className="dropdown-item" to="/">
                      Connexion
                    </Link>
                    <Link className="dropdown-item" to="/">
                      Inscription
                    </Link>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </div>
        </div>
      </header>

      {children}

      {/* <!-- FOOTER --> */}

      <footer id="footerBcm" className="footerFull">
        <div className="footerBlock1">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <div className="footerActionBlock">
                  <p>
                    Passez votre analyse minceur <br />
                    gratuitement avec Dr&nbsp;Cohen
                  </p>
                  <div>
                    <Link to="/cooking-minceur" className="cta-salmon">
                      JE COMMENCE
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="footerActionBlock">
                  <p>
                    Notre équipe est à votre écoute. <br />
                    Appelez-nous au 04&nbsp;11&nbsp;88&nbsp;01&nbsp;12
                  </p>
                  <div>
                    <a href="tel:+33411880112" className="cta-salmon">
                      J'APPELLE
                    </a>
                  </div>

                  <div className="smallCallNote">*Coût d'un appel local, du lundi au vendredi de 9H00 à 18h </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="footerActionBlock">
                  <p>
                    Retrouvez Jean-Michel Cohen sur <br />
                    <br />
                  </p>
                  <div>
                    <a
                      href="https://www.instagram.com/dr.jeanmichelcohen/"
                      target="_blank"
                      className="smi instagram"
                      title="Instagram"
                      rel="noreferrer"
                    >
                      <i className="fab fa-instagram-square"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/dr.jeanmichelcohen/"
                      target="_blank"
                      className="smi facebook"
                      title="Facebook"
                      rel="noreferrer"
                    >
                      <i className="fab fa-facebook-square"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/dr.jeanmichelcohen/"
                      target="_blank"
                      className="smi twitter"
                      title="Twitter"
                      rel="noreferrer"
                    >
                      <i className="fab fa-twitter-square"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footerBlock2">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <div className="siteLinks">
                  <h5>Assistance</h5>
                  <ul>
                    <li>
                      <a href="https://batchcooking.jeanmichelcohen.fr/page-de-paiement.asp">Inscription</a>
                    </li>
                    <li>
                      <Link to="/batch-cooking-cest-quoi">Connexion</Link>
                    </li>
                    <li>
                      <Link to="/faq">Aide - FAQ</Link>
                    </li>
                    <li>
                      <a href="https://batchcooking.jeanmichelcohen.fr/contact/conditionsdutilisation.asp">
                        Conditions d'utilisation
                      </a>
                    </li>
                    <li>
                      <a href="https://batchcooking.jeanmichelcohen.fr/contact/vieprivee.asp">
                        Charte sur la vie privée
                      </a>
                    </li>
                    <li>
                      <Link to="/contact-info">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="siteLinks">
                  <h5>Découvrir</h5>
                  <ul>
                    <li>
                      <Link to="/cooking-minceur">Faire mon Analyse</Link>
                    </li>
                    <li>
                      <Link to="/dr-cohen">Dr Jean-Michel Cohen</Link>
                    </li>
                    <li>
                      <Link to="/batch-cooking-cest-quoi">Batch Cooking Minceur </Link>
                    </li>
                    <li>
                      <Link to="/weekly-guide">Exemple de menus</Link>
                    </li>
                    <li>
                      <Link to="/batch-cooking-cest-quoi">Conseils pratiques</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="siteLinks">
                  <h5>Disclaimer </h5>
                  <p className="disclaimerText">
                    Les témoignages présentés sont des expériences individuelles. Ils ne sont ni caractéristiques, ni
                    garantis : les résultats peuvent varier d'une personne à l'autre. Comme pour tout programme de
                    rééquilibrage alimentaire, des plans de repas contrôlés et des exercices physiques réguliers sont
                    nécessaires pour perdre du poids à long terme. Demandez toujours l'avis de votre médecin traitant
                    avant d'entreprendre un régime amincissant, un programme sportif ou de modifier vos habitudes
                    nutritionnelles.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footerBlock3">
          <div className="container">
            <p className="copyrightText">
              © 2023 copyright et éditeur ANXA / powered by ANXA <br />
              Reproduction totale ou partielle interdite sans accord préalable. <br />
              Anxa collecte et traite les données personnelles dans le respect de la loi Informatique et Libertés
              (Déclaration CNIL No 1787863).
            </p>
          </div>
        </div>
      </footer>
    </main>
  );
};

export default Layout;
