import Layout from "./Layout";

function PageNotFound() {
  return (
    <Layout>
      <section>
        <h2>Page not found</h2>
        <p>Sorry page not found</p>
      </section>
    </Layout>
  );
}

export default PageNotFound;
