import { Link, useLocation, useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import PageNotFound from "./pages/PageNotFound";
import { AppLoadingIndicator } from "./pages/_components/AppLoadingIndicator";
import Root from "./pages/root/Root";
import Home from "./pages/root/Home";
import Faq from "./pages/root/Faq";
import useBreadcrumbs, { createRoutesFromChildren, Route } from "use-react-router-breadcrumbs";
import PageNotFound from "./pages/PageNotFound";
//import RouteErrorBoundary from "./pages/RouteErrorBoundary";
import DrCohen from "./pages/root/DrCohen";
import Programme from "./pages/root/Programme";
import BatchCooking from "./pages/root/BatchCooking";
import WeeklyGuide from "./pages/root/WeeklyGuide";
import ContactInfo from "./pages/contact/ContactInfo";
import Advantages from "./pages/root/Advantages";
import { useEffect } from "react";
import CommentSePasse from "./pages/root/CommentSePasse";
import CommentAdapter from "./pages/root/CommentAdapter";
import ConserverPlatsSemaine from "./pages/root/ConserverPlatsSemaine";
import RechaufferLesServir from "./pages/root/RechaufferLesServir";
import ProduitsLaMaison from "./pages/root/ProduitsLaMaison";
import AlimentsRechauffer from "./pages/root/AlimentsRechauffer";
import AlimentsRefrigerateur from "./pages/root/AlimentsRefrigerateur";
import EquipementBesoin from "./pages/root/EquipementBesoin";
import DietProfileRoot from "./pages/dietprofile/DietProfileRoot";
import DietProfileIdeal from "./pages/dietprofile/DietProfileIdeal";
import DietProfileGender from "./pages/dietprofile/DietProfileGender";
import DietProfileUserDetails from "./pages/dietprofile/DietProfileUserDetails";
import { DietProfilePath } from "./pages/dietprofile";
import DietProfileGraph from "./pages/dietprofile/DietProfileGraph";
import DietProfileFigure from "./pages/dietprofile/DietProfileFigure";
import DietProfileExces from "./pages/dietprofile/DietProfileExces";
import DietProfileActivity from "./pages/dietprofile/DietProfileActivity";
import DietProfileQuiz from "./pages/dietprofile/DietProfileQuiz";
import DietProfileConnaissez from "./pages/dietprofile/DietProfileConnaissez";
import DietProfileEquipement from "./pages/dietprofile/DietProfileEquipement";
import DietProfilePreparer from "./pages/dietprofile/DietProfilePreparer";
import DietProfilePoids from "./pages/dietprofile/DietProfilePoids";
import DietProfileResults from "./pages/dietprofile/DietProfileResults";
import DietProfileLoaderForm from "./pages/dietprofile/DietProfileLoaderForm";

export const Breadcrumbs = () => {
  const AppRoutes = GenerateAppRoutes();
  const appRouteObjects = createRoutesFromChildren(AppRoutes);

  const breadcrumbs = useBreadcrumbs(appRouteObjects);

  if (breadcrumbs.length === 0) return null;

  return (
    <section className="breadcrumbs-section">
      <div className="container-fluid">
        <div className="breadcrumbs">
          <Link to="/">Accueil</Link>
          <span>/</span>
          <a href="#conseils">Conseils</a>
          <span>/</span>
          {breadcrumbs.map(({ breadcrumb }) => breadcrumb)}
        </div>
      </div>
    </section>
  );
};

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const GenerateAppRoutes = () => {
  return [
    <Route path="/" element={<Root />}>
      <Route index element={<Home />} breadcrumb={null} />
      <Route path="contact-info" element={<ContactInfo />} breadcrumb={null} />
      <Route path="faq" element={<Faq />} breadcrumb="FAQ" />
      <Route path="dr-cohen" element={<DrCohen />} breadcrumb="Je suis le Dr Jean-Michel Cohen" />
      <Route path="programme" element={<Programme />} breadcrumb="Tout ce qu’il y a dans le programme !" />
      <Route
        path="batch-cooking-cest-quoi"
        element={<BatchCooking />}
        breadcrumb="Le batch Cooking Minceur Dr Cohen, c'est quoi ?"
      />
      <Route path="weekly-guide" element={<WeeklyGuide />} breadcrumb="Une semaine de Batch Cooking Minceur" />

      <Route path="5-avantages" element={<Advantages />} breadcrumb="Les 5 avantages du Batch Cooking Minceur" />
      <Route
        path="comment-se-passe"
        element={<CommentSePasse />}
        breadcrumb="1 Semaine de Batch-Cooking : comment ça se passe ?"
      />
      <Route
        path="comment-adapter"
        element={<CommentAdapter />}
        breadcrumb="Comment adapter le Batch Cooking Minceur pour toute la famille ?"
      />
      <Route
        path="conserver-plats-semaine"
        element={<ConserverPlatsSemaine />}
        breadcrumb="Comment bien conserver mes plats de la semaine ?"
      />
      <Route
        path="rechauffer-les-servir"
        element={<RechaufferLesServir />}
        breadcrumb="Comment réchauffer les plats et les servir ?"
      />
      <Route
        path="produits-la-maison"
        element={<ProduitsLaMaison />}
        breadcrumb="Quels produits dois-je toujours avoir à la maison ?"
      />
      <Route path="aliments-rechauffer" element={<AlimentsRechauffer />} breadcrumb="Quelques notions d’hygiène" />
      <Route
        path="aliments-refrigerateur"
        element={<AlimentsRefrigerateur />}
        breadcrumb="Quels aliments ne se conservent pas au réfrigérateur ?"
      />
      <Route
        path="equipement-besoin"
        element={<EquipementBesoin />}
        breadcrumb="Les 5 avantages du Batch Cooking Minceur"
      />
    </Route>,
    <Route path="cooking-minceur" element={<DietProfileRoot />}>
      <Route index element={<DietProfileGender />} />
      <Route path={DietProfilePath.SelectIdeal} element={<DietProfileIdeal />} />
      <Route path={DietProfilePath.UserDetails} element={<DietProfileUserDetails />} />
      <Route path={DietProfilePath.DietGraph} element={<DietProfileGraph />} />
      <Route path={DietProfilePath.Figure} element={<DietProfileFigure />} />
      <Route path={DietProfilePath.Exces} element={<DietProfileExces />} />
      <Route path={DietProfilePath.Activity} element={<DietProfileActivity />} />
      <Route path={DietProfilePath.Quiz} element={<DietProfileQuiz />} />
      <Route path={DietProfilePath.Connaissez} element={<DietProfileConnaissez />} />
      <Route path={DietProfilePath.Equipement} element={<DietProfileEquipement />} />
      <Route path={DietProfilePath.Preparer} element={<DietProfilePreparer />} />
      <Route path={DietProfilePath.Poids} element={<DietProfilePoids />} />
      <Route path={DietProfilePath.LoaderForm} element={<DietProfileLoaderForm />} />
      {/* <Route path={DietProfilePath.Results} element={<DietProfileResults />} /> */}
    </Route>,
    <Route path="cooking-minceur">
      <Route path={DietProfilePath.Results} element={<DietProfileResults />} />
    </Route>,
    <Route path="*" element={<PageNotFound />} />,
  ];
};

function App() {
  const AppRoutes = GenerateAppRoutes();
  const appRouteObjects = createRoutesFromChildren(AppRoutes);
  const GeneratedRoutes = useRoutes(appRouteObjects);

  return (
    <>
      {GeneratedRoutes}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <AppLoadingIndicator />
    </>
  );
}

export default App;
