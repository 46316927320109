import classNames from "classnames";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Fade } from "reactstrap";
import { DietProfilePath } from ".";
import { CurrentUser } from "../../controllers/CurrentUser";
import DietProfileContainer from "./DietProfileContainer";

const DietProfileQuiz = () => {
  const [quiz, setQuiz] = useState<boolean | undefined>(CurrentUser.profile.quiz);
  const ref = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const onSelect = (value: boolean) => {
    setQuiz(value);
    setTimeout(() => {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  const onContinue = () => {
    CurrentUser.profile.quiz = quiz;

    setTimeout(() => {
      navigate(DietProfilePath.Connaissez, { replace: true });
    }, 500);
  };

  return (
    <DietProfileContainer>
      <div className="dpq-wrapper">
        <div className="dpq-question   d-block">
          <div className="text-center">
            <img src="/dietprofile-images/bcm-dp-quiz.jpg" alt="" className="img-fluid mx-auto" />
          </div>
          <h3 className="text-center emerald">
            <strong>Pensez-vous que pour maigrir, il faut&nbsp;:</strong>
          </h3>

          <div style={{ width: "80%", margin: "0 auto 15px auto" }}>
            <div className="row">
              <div className="col-sm-6 text-center">
                <div>• Arrêter les écarts</div>
                <div>• Sauter des repas</div>
              </div>
              <div className="col-sm-6 text-center">
                <div>• Arrêter les féculents &nbsp;&nbsp;</div>
                <div>• Supprimer le chocolat</div>
              </div>
            </div>
          </div>

          <div className="quickquiz text-center">
            <label onClick={() => onSelect(true)} className={classNames({ selected: quiz === true })}>
              OUI
            </label>

            <label onClick={() => onSelect(false)} className={classNames({ selected: quiz === false })}>
              NON
            </label>
          </div>
          {quiz === true && (
            <Fade in={true} className="quizResult d-block">
              <h5 className="salmon">
                <strong>Réjouissez-vous !</strong>
              </h5>
              <div>
                Ce sont des mythes trop répandus : manger équilibré et surtout savoir apprécier la qualité d’une
                nourriture saine sont les vraies clés de votre réussite.
              </div>
            </Fade>
          )}
          {quiz === false && (
            <Fade in={true} className="quizResult d-block">
              <h5 className="salmon">
                <strong>Vous avez bien raison&nbsp;!</strong>
              </h5>
              <div>
                On a trop souvent lié perte de poids et sacrifice : c’est faux ! La vraie clé de la réussite, c’est
                d’apprécier manger sain et équilibré. Le reste suit tout seul !
              </div>
            </Fade>
          )}

          <br />
          {quiz !== undefined && (
            <div className="text-center" ref={ref}>
              <button type="button" className="cta-salmon" onClick={onContinue}>
                Continuer
              </button>
            </div>
          )}
        </div>
      </div>
    </DietProfileContainer>
  );
};

export default DietProfileQuiz;
