import CommencerCard from "../shared/CommencerCard";
import VosQuestions from "../shared/VosQuestions";

const Programme = () => {
  return (
    <section className="level2-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="bcm-leftcol-wrap">
              <h1 className="emerald">
                <strong>Tout ce qu’il y a dans le programme !</strong>
              </h1>
              <p>
                <img src="images/bcm-content-programme.jpg" alt="Programme" className="img-fluid" />
              </p>

              <p>
                Le Batch Cooking Minceur est une solution globale pour vous permettre de manger varié et équilibré, avec
                un budget maîtrisé, le tout en passant le moins de temps possible en cuisine. Je vous propose en effet
                de préparer tous vos repas de la semaine, en consacrant seulement 2 heures de cuisine, le weekend
                précédent.
              </p>
              <p>
                Plans de repas variés, listes de courses réduites, conseils d’organisation, recettes simples et
                pratiques… Avec le Batch Cooking, vous êtes guidée du début à la fin.
              </p>

              <p>Vous allez savoir :</p>
              <ul>
                <li>Comment faire des économies sur vos listes de courses en fonction de vos plans de repas, </li>
                <li>Comment adapter vos achats selon les promotions et autres bonnes affaires.</li>
                <li>Comment vous organiser pour cuisiner une dizaine de plats en seulement deux heures.</li>
                <li>Comment stocker vos repas pour bien les conserver et les répartir dans la semaine.</li>
                <li>Comment manger équilibré simplement, contrôler sa ligne et perdre du poids.</li>
              </ul>
              <br />
              <h2 className="salmon">
                <strong>Qu'est-ce qui est inclus&nbsp;?</strong>
              </h2>
              <p>Le Batch Cooking Minceur Dr Cohen, c’est chaque semaine :</p>
              <ul>
                <li>5 plats minceur à cuisiner vous-même en deux heures.</li>
                <li>Une liste de courses optimisée avec 25 produits maximum à acheter en une seule fois.</li>
                <li>Un budget alimentation contrôlé, très appréciable en période de forte inflation.</li>
                <li>Un atelier vidéo Batch Cooking Minceur live pour poser toutes vos questions aux experts.</li>
                <li>La garantie de perdre du poids à votre rythme en cuisinant vous-même.</li>
              </ul>
              <p>
                Chaque lundi, vous recevez votre menu Batch Cooking Minceur correspondant à la semaine suivante. Vous
                avez ainsi tout le temps pour vous organiser pour les courses et la séance de cuisine de 2 heures
                (recommandée le weekend).
              </p>
              <p>
                En rejoignant le Batch Cooking Minceur, vous aurez également accès à votre courbe de suivi de poids et
                au grand forum pour échanger avec les autres utilisatrices.
              </p>
              <p>
                Commencez maintenant avec votre première séance de cuisine et vous verrez qu’on peut vraiment maigrir en
                se simplifiant la vie.
              </p>

              <br />
              <hr />
              <br />

              <VosQuestions />
            </div>
          </div>
          <div className="col-lg-4">
            <CommencerCard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Programme;
