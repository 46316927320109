import { Link } from "react-router-dom";
import BrowserUtil from "../../utils/BrowserUtil";

interface Props {
  hideTitle?: boolean;
}

const VosQuestions = (props: Props) => {
  return (
    <>
      {!props.hideTitle && (
        <>
          <h2 className="emerald">
            <strong>Vos questions</strong>
          </h2>
          <br />
        </>
      )}
      <ul>
        <li>
          <p>
            <Link to="/batch-cooking-cest-quoi" onClick={BrowserUtil.scrollToTop}>
              <strong>Le batch Cooking Minceur Dr Cohen, c'est quoi ? </strong>
            </Link>
          </p>
        </li>
        <li>
          <p>
            <Link to="/5-avantages" onClick={BrowserUtil.scrollToTop}>
              <strong>Quels sont les avantages du Batch Cooking Minceur ?</strong>
            </Link>
          </p>
        </li>
        <li>
          <p>
            <Link to="/comment-se-passe" onClick={BrowserUtil.scrollToTop}>
              <strong>1 Semaine de Batch-Cooking : comment ça se passe ?</strong>
            </Link>
          </p>
        </li>
        <li>
          <p>
            <Link to="/comment-adapter" onClick={BrowserUtil.scrollToTop}>
              <strong>Comment adapter le Batch Cooking Minceur pour toute la famille ? </strong>
            </Link>
          </p>
        </li>
        <li>
          <p>
            <Link to="/conserver-plats-semaine" onClick={BrowserUtil.scrollToTop}>
              <strong>Comment bien conserver mes plats de la semaine ?</strong>
            </Link>
          </p>
        </li>
        <li>
          <p>
            <Link to="/rechauffer-les-servir" onClick={BrowserUtil.scrollToTop}>
              <strong>Comment réchauffer les plats et les servir ?</strong>
            </Link>
          </p>
        </li>
        <li>
          <p>
            <Link to="/produits-la-maison" onClick={BrowserUtil.scrollToTop}>
              <strong>Quels produits dois-je toujours avoir à la maison ?</strong>
            </Link>
          </p>
        </li>
        <li>
          <p>
            <Link to="/aliments-rechauffer" onClick={BrowserUtil.scrollToTop}>
              <strong>Quels aliments ne faut-il jamais réchauffer ? </strong>
            </Link>
          </p>
        </li>
        <li>
          <p>
            <Link to="/aliments-refrigerateur" onClick={BrowserUtil.scrollToTop}>
              <strong>Quels aliments ne se conservent pas au réfrigérateur ?</strong>
            </Link>
          </p>
        </li>
        <li>
          <p>
            <Link to="/equipement-besoin" onClick={BrowserUtil.scrollToTop}>
              <strong>De quel équipement ai-je besoin ?</strong>
            </Link>
          </p>
        </li>
      </ul>
    </>
  );
};

export default VosQuestions;
