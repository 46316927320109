import CommencerCard from "../shared/CommencerCard";
import VosQuestions from "../shared/VosQuestions";

const AlimentsRefrigerateur = () => {
  return (
    <section className="level2-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="bcm-leftcol-wrap">
              <h1 className="emerald">
                <strong>Quels aliments ne se conservent pas au réfrigérateur ?</strong>
              </h1>
              <p>
                <img
                  src="images/bcm-content-aliments-refrigerateur.jpg"
                  alt="aliments refrigerateur"
                  className="img-fluid"
                />
              </p>
              <h2 className="salmon">
                <strong>A mettre au frais :</strong>
              </h2>

              <ul>
                <li>
                  Tous les <strong>produits frais et sensibles</strong> sur un point bactériologique : viandes,
                  charcuteries, poissons, fruits de mer, produits laitiers frais (y compris fromages et la crème
                  fraîche), le beurre et la margarine, les produits du traiteur et les plats préparés frais.{" "}
                  <strong>La règle&nbsp;: Tout ce qui est acheté réfrigéré doit être placé au réfrigérateur</strong>
                  &nbsp;!
                </li>
                <li>
                  Tous les <strong>restes</strong> et <strong>plats cuisinés maison</strong>, dans un récipient
                  hermétique ou recouvert de film alimentaire.
                </li>
                <li>
                  Les produits entamés portant la mention <strong>"à conserver au froid après ouverture"</strong>, c'est
                  le cas du lait même UHT, des jus de fruit… Il est conseillé dans ce cas de préciser la date
                  d'ouverture.
                </li>
                <li>
                  Certaines <strong>huiles riches en acides gras polyinsaturés</strong> facilement oxydables et en
                  vitamines sensibles comme l'huile de noix qui rancit rapidement ou de l’huile de germe de blé.
                </li>
                <li>
                  Certaines <strong>farines biologiques sans conservateur</strong> une fois le paquet entamé en raison
                  de la présence éventuelle de mites alimentaires.
                </li>
              </ul>
              <p>
                A l’inverse, certains aliments ont, en effet, tendance à se dénaturer au réfrigérateur : ils peuvent
                alors perdre leur couleur, leur texture naturelle ou même leur saveur. Quel dommage !
              </p>

              <ul>
                <li>
                  <strong>Les fruits et les légumes.</strong>
                  <br />
                  Le froid va ralentir le processus de maturation. Ainsi pour des produits plus savoureux et mûrs à
                  point, il est conseillé de ranger les fruits dans une corbeille. Ces précautions sont notamment
                  valables pour les fruits qui viennent des pays chauds (ananas, mangues, bananes), mais également les
                  tomates et avocats.
                  <br />
                  <br />
                  Certains fruits comme les bananes brunissent au frigo et devront rester à l'air libre. Rappelons tout
                  de même que pour des fruits achetés mûrs à point, la conservation au réfrigérateur permet d'éviter les
                  pertes selon votre rythme de consommation. Placez-les alors dans le bac à légumes qui reste modérément
                  froid et sortez-les au fur et à mesure. <br />
                  <br />
                  Notez également que certaines vitamines fragiles notamment altérées par la lumière et/ou la chaleur
                  seront altérées si le temps entre l'achat et la consommation est long. Tout produit préparé sous forme
                  de salade de fruits ou légumes préparés devra rester au frais.<p></p>
                </li>

                <li>
                  <strong>Les pommes de terre</strong> doivent éviter le froid, mais il est important de les conserver à
                  l'abri de la lumière pour éviter le verdissement.
                </li>
                <li>
                  {" "}
                  <strong>Le chocolat</strong>&nbsp;: rappelons qu'il fond autour de 45°C donc mise à part une chaleur
                  intense, évitez le réfrigérateur qui le ferait cristalliser et altèrerait notablement les arômes.
                </li>
                <li>
                  <strong>Oignons, ail, échalotes entiers</strong>, pour éviter d'altérer les saveurs et de souiller le
                  réfrigérateur.
                </li>
                <li>
                  <strong>Le café</strong>&nbsp;: certaines pensent bien faire en le stockant au frais, pourtant la
                  condensation altère la qualité du produit et ses arômes. L'important reste de le conserver dans un
                  contenant hermétique.
                </li>
                <li>
                  <strong>Le miel</strong>, le froid le ferait cristalliser et ce produit reste stable à température
                  ambiante.
                </li>
                <li>
                  <strong>La confiture</strong>, une confiture classique contient 50% de sucre et est donc un produit
                  stable qui ne nécessite pas de réfrigération à condition de bien refermer le pot hermétiquement. Ce
                  n'est pas le cas des produits allégés !
                </li>

                <li>Le ketchup ou la sauce tomate avant ouverture.</li>
                <li>Les charcuteries sèches et non tranchées. </li>
                <li>Les pâtes à tartiner. </li>
                <li>Les poissons en conserve avant ouverture.</li>
                <li>
                  les œufs (sauf les œufs qui sont lavés avant d’être vendus car ils requièrent une réfrigération
                  obligatoire). Il est important que la température de conservation reste plutôt fraîche et constante.
                  Donc soyez attentifs en été et en hiver selon la température de chauffage. Et la coquille de l'œuf
                  étant poreuse, l’environnement de stockage doit être le plus sain possible.
                </li>
              </ul>

              <br />
              <hr />
              <br />
              <VosQuestions />
            </div>
          </div>
          <div className="col-lg-4">
            <CommencerCard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AlimentsRefrigerateur;
