import { Link } from "react-router-dom";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import "./bcm-dietprofile.css";

const DietProfileHeader = () => {
  return (
    <header id="BcmDPHeader">
      <div className="container-fluid">
        <div className="headPad">
          <Link to="/" className="text-center bcm-header-logo">
            <img
              src="/images/bcm-header-logo-d.png"
              alt="Batch Cooking Minceur COHEN"
              className="img-fluid d-none d-sm-block mx-auto"
            />
            <img
              src="/images/bcm-header-logo-d.png"
              alt="Batch Cooking Minceur COHEN"
              className="img-fluid d-block d-sm-none mx-auto"
            />
          </Link>

          <div className="rightHead ml-auto" style={{ marginLeft: "auto" }}>
            <div className="d-block">
              <UncontrolledDropdown>
                <DropdownToggle className="menuMobile">
                  <i className="fas fa-bars"></i>
                </DropdownToggle>
                <DropdownMenu end>
                  <Link className="dropdown-item" to="/dr-cohen">
                    Dr Cohen
                  </Link>
                  <Link className="dropdown-item" to="/batch-cooking-cest-quoi">
                    Batch Cooking ?
                  </Link>
                  <Link className="dropdown-item" to="/programme">
                    Programme
                  </Link>
                  <Link className="dropdown-item" to="/weekly-guide">
                    Exemple de menus
                  </Link>
                  <Link className="dropdown-item" to="/faq">
                    FAQ
                  </Link>
                  <Link className="dropdown-item" to="/contact-info">
                    Contact
                  </Link>
                  <DropdownItem divider />
                  <Link className="dropdown-item" to="/">
                    Connexion
                  </Link>
                  <Link className="dropdown-item" to="/">
                    Inscription
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default DietProfileHeader;
