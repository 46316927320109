const WeeklyGuide = () => {
  return (
    <section className="level2-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="bcm-fullwidth-wrap">
              <h1 className="emerald">
                <strong>Semaine du 12 au 18 oct.</strong>
              </h1>

              <div className="anchors">
                <div>
                  <a href="#W-MealPlan">Menus</a>
                </div>
                <div>
                  <a href="#W-Courses">Liste de courses</a>
                </div>
                <div>
                  <a href="#W-Preparation">Méthode </a>
                </div>
                <div>
                  <a href="#W-Recipe">Recettes </a>
                </div>
                <div>
                  <a href="BCMEbook.pdf" target="_blank">
                    Ebook
                  </a>
                </div>
              </div>

              {/* <script>
                            $(document).on('click', '.anchors a[href^="#"]', function (event) {
                                event.preventDefault();

                                $('html, body').animate({
                                    scrollTop: $($.attr(this, 'href')).offset().top
                                }, 500);
                            });
                        </script> */}
              <div className="bcm-weekly-guide-wrap">
                <div className="wg-meal-plan" id="W-MealPlan">
                  <h2 className="text-center salmon">
                    <strong>On mange quoi cette semaine ?</strong>
                  </h2>

                  <div className="mp-dayWrap">
                    <div className="cpMealflex">
                      <div className="cpContent">
                        <div className="dayMeals">
                          <div className="text-center">
                            <h4 className="dayName">Lundi</h4>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <div className="mp-mobile-image">
                                <img src="images/bcm-wg-meal-lundi.jpg" alt="" className="img-fluid" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <h5>
                                <strong className="salmon">Déjeuner</strong>
                              </h5>
                              <ul>
                                <li>Carottes au yaourt (vraie portion de légumes de 200 / 250 g)</li>
                                <li>2 tranches de filet mignon froid à la moutarde</li>
                                <li>40 g de pain</li>
                                <li>1 laitage nature 0% de MG</li>
                                <li>100 g de compote sans sucre ajouté</li>
                              </ul>
                            </div>
                            <div className="col-lg-6">
                              <h5>
                                <strong className="salmon">Dîner</strong>
                              </h5>
                              <ul>
                                <li>2 oeufs à la coque (cuisson 3 minutes)</li>
                                <li>Fondue de poireaux</li>
                                <li>2 CS de crème à 15%</li>
                                <li>1 portion de fromage</li>
                                <li>15 g de pain de boulangerie</li>
                                <li>1 pomme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cpImage">
                        <div className="mp-image">
                          <img src="images/bcm-wg-meal-lundi.jpg" alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mp-dayWrap">
                    <div className="cpMealflex">
                      <div className="cpImage">
                        <div className="mp-image">
                          <img src="images/bcm-wg-meal-mardi.jpg" alt="" className="img-fluid" />
                        </div>
                      </div>
                      <div className="cpContent">
                        <div className="dayMeals">
                          <div className="text-center">
                            <h4 className="dayName">Mardi</h4>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <div className="mp-mobile-image">
                                <img src="images/bcm-wg-meal-mardi.jpg" alt="" className="img-fluid" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <h5>
                                <strong className="salmon">Déjeuner</strong>
                              </h5>
                              <ul>
                                <li>80 g filets de sardine en marinade sans huile</li>
                                <li>Poireaux froids en vinaigrette</li>
                                <li>1 portion de fromage</li>
                                <li>15 g de pain de boulangerie</li>
                                <li>1 pomme</li>
                              </ul>
                            </div>
                            <div className="col-lg-6">
                              <h5>
                                <strong className="salmon">Dîner</strong>
                              </h5>
                              <ul>
                                <li>2 oeufs à la coque (cuisson 3 minutes)</li>
                                <li>Fondue de poireaux</li>
                                <li>2 CS de crème à 15%</li>
                                <li>1 portion de fromage</li>
                                <li>15 g de pain de boulangerie</li>
                                <li>1 pomme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mp-dayWrap">
                    <div className="cpMealflex">
                      <div className="cpContent">
                        <div className="dayMeals">
                          <div className="text-center">
                            <h4 className="dayName">Mercredi</h4>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <div className="mp-mobile-image">
                                <img src="images/bcm-wg-meal-mercredi.jpg" alt="" className="img-fluid" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <h5>
                                <strong className="salmon">Déjeuner</strong>
                              </h5>
                              <ul>
                                <li>Filet mignon aux carottes (portions à réchauffer) et à la badiane</li>
                                <li>100 g poids cuit de riz (cuire 2 portions)</li>
                                <li>5 g de beurre</li>
                                <li>1 laitage nature 0% de MG</li>
                                <li>1 poire</li>
                              </ul>
                            </div>
                            <div className="col-lg-6">
                              <h5>
                                <strong className="salmon">Dîner</strong>
                              </h5>
                              <ul>
                                <li>Potage aux poireaux (portion 1)</li>
                                <li>1 CS de crème à 15%</li>
                                <li>2 tranches de jambon découenné dégraissé</li>
                                <li>1 portion de fromage</li>
                                <li>15 g de pain de boulangerie</li>
                                <li>100 g de compote sans sucre ajouté</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cpImage">
                        <div className="mp-image">
                          <img src="images/bcm-wg-meal-mercredi.jpg" alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mp-dayWrap">
                    <div className="cpMealflex">
                      <div className="cpImage">
                        <div className="mp-image">
                          <img src="images/bcm-wg-meal-jeudi.jpg" alt="" className="img-fluid" />
                        </div>
                      </div>
                      <div className="cpContent">
                        <div className="dayMeals">
                          <div className="text-center">
                            <h4 className="dayName">Jeudi</h4>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <div className="mp-mobile-image">
                                <img src="images/bcm-wg-meal-jeudi.jpg" alt="" className="img-fluid" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <h5>
                                <strong className="salmon">Déjeuner</strong>
                              </h5>
                              <ul>
                                <li>2 tranches de filet mignon froid à la moutarde</li>
                                <li>Carottes râpées en vinaigrette</li>
                                <li>1 portion de fromage</li>
                                <li>15 g de pain de boulangerie</li>
                                <li>100 g de compote sans sucre ajouté</li>
                              </ul>
                            </div>
                            <div className="col-lg-6">
                              <h5>
                                <strong className="salmon">Dîner</strong>
                              </h5>
                              <ul>
                                <li>Chou à la farce légère</li>
                                <li>100 g poids cuit de riz (portion 2)</li>
                                <li>5 g de beurre</li>
                                <li>1 laitage nature 0% de MG</li>
                                <li>1 pomme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mp-dayWrap">
                    <div className="cpMealflex">
                      <div className="cpContent">
                        <div className="dayMeals">
                          <div className="text-center">
                            <h4 className="dayName">Vendredi</h4>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <div className="mp-mobile-image">
                                <img src="images/bcm-wg-meal-vendredi.jpg" alt="" className="img-fluid" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <h5>
                                <strong className="salmon">Déjeuner</strong>
                              </h5>
                              <ul>
                                <li>Hachis parmentier diététique</li>
                                <li>Salade verte en vinaigrette</li>
                                <li>1 poire</li>
                              </ul>
                            </div>
                            <div className="col-lg-6">
                              <h5>
                                <strong className="salmon">Dîner</strong>
                              </h5>
                              <ul>
                                <li>Potage aux poireaux (portion 2)</li>
                                <li>1 CS de crème à 15%</li>
                                <li>80 g filets de sardine en marinade sans huile</li>
                                <li>15 g de pain de boulangerie grillé en croûtons </li>
                                <li>1 laitage nature 0% de MG</li>
                                <li>1 pomme</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cpImage">
                        <div className="mp-image">
                          <img src="images/bcm-wg-meal-vendredi.jpg" alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mp-dayWrap">
                    <div className="cpMealflex">
                      <div className="cpImage">
                        <div className="mp-image">
                          <img src="images/bcm-wg-meal-samedi.jpg" alt="" className="img-fluid" />
                        </div>
                      </div>
                      <div className="cpContent">
                        <div className="dayMeals">
                          <div className="text-center">
                            <h4 className="dayName">Samedi</h4>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <div className="mp-mobile-image">
                                <img src="images/bcm-wg-meal-samedi.jpg" alt="" className="img-fluid" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <h5>
                                <strong className="salmon">Déjeuner</strong>
                              </h5>
                              <ul>
                                <li>
                                  1 filet de cabillaud en papillote au micro-ondes, jus de citron (cuire 2 filets)
                                </li>
                                <li>Purée de brocolis</li>
                                <li>5 g de beurre</li>
                                <li>1 portion de fromage</li>
                                <li>15 g de pain de boulangerie</li>
                                <li>1 pomme</li>
                              </ul>
                            </div>
                            <div className="col-lg-6">
                              <h5>
                                <strong className="salmon">Dîner</strong>
                              </h5>
                              <ul>
                                <li>Omelette au chou</li>
                                <li>Salade verte et 100 g de maïs doux</li>
                                <li>Vinaigrette</li>
                                <li>1 laitage nature 0% de MG</li>
                                <li>100 g de compote sans sucre ajouté</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mp-dayWrap">
                    <div className="cpMealflex">
                      <div className="cpContent">
                        <div className="dayMeals">
                          <div className="text-center">
                            <h4 className="dayName">Dimanche</h4>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <div className="mp-mobile-image">
                                <img src="images/bcm-wg-meal-dimanche.jpg" alt="" className="img-fluid" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <h5>
                                <strong className="salmon">Déjeuner</strong>
                              </h5>
                              <ul>
                                <li>1 filet de cabillaud froid, coulis de tomate</li>
                                <li>Salade verte et 125 g de pommes de terre vapeur froides</li>
                                <li>Vinaigrette</li>
                                <li>1 laitage nature 0% de MG</li>
                                <li>2 kiwis</li>
                              </ul>
                            </div>
                            <div className="col-lg-6">
                              <h5>
                                <strong className="salmon">Dîner</strong>
                              </h5>
                              <ul>
                                <li>Potage aux poireaux (portion 3)</li>
                                <li>1 CS de crème à 15%</li>
                                <li>2 tranches de jambon découenné dégraissé</li>
                                <li>1 portion de fromage</li>
                                <li>15 g de pain de boulangerie</li>
                                <li>1 poire</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cpImage">
                        <div className="mp-image">
                          <img src="images/bcm-wg-meal-dimanche.jpg" alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mp-dayWrap">
                    <div className="cpMealflex">
                      <div className="cpImage">
                        <div className="mp-image">
                          <img src="images/bcm-wg-meal-pd.jpg" alt="" className="img-fluid" />
                        </div>
                      </div>
                      <div className="cpContent">
                        <div className="dayMeals">
                          <div className="text-center">
                            <h4 className="dayName" style={{ backgroundColor: "#d7493b" }}>
                              Petit déjeuner
                            </h4>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="mp-mobile-image">
                                <img src="images/bcm-wg-meal-pd.jpg" alt="" className="img-fluid" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <ul
                                // style="list-style:none;"
                                className="list-group-none"
                              >
                                <li>
                                  <div>
                                    <em>
                                      Tous les matins, vous pouvez composer votre petit déjeuner en respectant le cadre
                                      suivant.{" "}
                                    </em>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="col-lg-6">
                              <ul>
                                <li>Boisson chaude sans sucre ou édulcorée : café, thé, infusion...</li>
                                <li>30 g de pain de boulangerie</li>
                                <li>10 g de matières grasses </li>
                                <li>1 laitage nature 0% de MG</li>
                                <li>2 kiwis ou 1 poire ou 1 compote sans sucre </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="wg-courses" id="W-Courses">
                  <h2 className="text-center">
                    <strong>On achète quoi cette semaine&nbsp;?</strong>
                  </h2>
                  <div className="text-center">
                    <h4 className="cost-tag">Prix estimé : 60 € environ </h4>
                  </div>

                  <div className="row">
                    <div className="col-lg-4">
                      <div className="course-category-block">
                        <div className="cc-head">
                          <h4>
                            Boulangerie <i className="fal fa-bread-loaf"></i>
                          </h4>
                        </div>
                        <ul>
                          <li>Pain complet (360 g)</li>
                        </ul>
                      </div>

                      <div className="course-category-block">
                        <div className="cc-head">
                          <h4>
                            Crèmerie <i className="fal fa-cheese-swiss"></i>
                          </h4>
                        </div>
                        <ul>
                          <li>14 laitages 0% nature (&lt;55 kcal /&gt;pot)</li>
                          <li>1 petite plaquette de beurre</li>
                          <li>1 briquette de crème à 15% de MG</li>
                          <li>4 oeufs </li>
                          <li>1 fromage au choix </li>
                        </ul>
                      </div>

                      <div className="course-category-block">
                        <div className="cc-head">
                          <h4>
                            Epicerie <i className="fal fa-shopping-basket"></i>
                          </h4>
                        </div>
                        <ul>
                          <li>80 g sec de quinoa</li>
                          <li>60 g sec de riz </li>
                          <li>100 g de maïs doux</li>
                          <li>160 g de sardines en conserve en marinade sans huile</li>
                          <li>700 g de compote sans sucre ajouté (7&nbsp;portions)</li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="course-category-block">
                        <div className="cc-head">
                          <h4>
                            Légumes <i className="fal fa-carrot"></i>
                          </h4>
                        </div>
                        <ul>
                          <li>1.5 kg de carottes </li>
                          <li>600 g de chou vert chou </li>
                          <li>300 g de salade verte </li>
                          <li>1 botte de poireaux</li>
                          <li>300 g de pomme de terre</li>
                        </ul>
                      </div>

                      <div className="course-category-block">
                        <div className="cc-head">
                          <h4>
                            Fruits <i className="fal fa-apple-alt"></i>
                          </h4>
                        </div>
                        <ul>
                          <li>5 pommes </li>
                          <li>8 kiwis </li>
                          <li>5 poires </li>
                        </ul>
                      </div>

                      <div className="course-category-block">
                        <div className="cc-head">
                          <h4>
                            Surgelés <i className="fal fa-steak"></i>
                          </h4>
                        </div>
                        <ul>
                          <li>2 pavés de cabillaud </li>
                          <li>1 steaks hachés </li>
                          <li>1 sachet de purée de brocolis sans féculents ni MG</li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="course-category-block">
                        <div className="cc-head">
                          <h4>
                            Viandes et charcuteries <i className="fal fa-meat"></i>
                          </h4>
                        </div>
                        <ul>
                          <li>450 g de filet mignon de porc</li>
                          <li>5 tranches de jambon découenné dégraissé</li>
                          <li>1 filet de poulet</li>
                        </ul>
                      </div>

                      <div className="course-category-block">
                        <div className="cc-head">
                          <h4>
                            Indispensables <i className="fal fa-wine-bottle"></i>
                            <br />
                            <span style={{ fontSize: "1.3rem" }}>(achat optionnel selon vos stocks)</span>
                          </h4>
                        </div>
                        <ul>
                          <li>Moutarde</li>
                          <li>Huile</li>
                          <li>Vinaigre</li>
                          <li>Badiane, paprika, muscade / ail, 3 oignons / persil</li>
                          <li>Cubes de bouillon de légumes</li>
                          <li>1 briquette de coulis de tomate</li>
                          <li>Vos boissons pour le petit déjeuner (café, thé, infusion...)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="wg-preparation" id="W-Preparation">
                  <h2 className="emerald text-center">
                    <strong>On commence par quoi cette semaine&nbsp;?</strong>
                  </h2>
                  <div className="text-center">
                    <h4 className="cost-tag">Temps de préparation : 1 heure environ</h4>
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-lg-6">
                      <div className="prep-block">
                        <div className="prep-head">
                          <h4>1. Les carottes</h4>
                          <img src="images/bcm-carotte.jpg" alt="carotte" className="img-fluid" />
                        </div>

                        <ul>
                          <li>Laver et peler les carottes (en réserver 2 pour le potage de poireaux) </li>
                          <li>
                            Couper environ 600 g en rondelles et les cuire sans matières grasses au choix à la vapeur, à
                            l'étouffée ou à l'eau.
                          </li>
                          <li>
                            Pendant ce temps : Râper le reste des carottes, citronner légèrement et stocker au
                            réfrigérateur dans un récipient hermétique ou recouvert de film étirable
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="prep-block">
                        <div className="prep-head">
                          <h4>2. Les poireaux</h4>
                          <img src="images/bcm-poireaux.jpg" alt="poireaux" className="img-fluid" />
                        </div>

                        <ul>
                          <li>Laver et éplucher les poireaux. Blancs et verts utilisés séparément</li>
                          <li>
                            Émincer les blancs et les cuire sans matières grasses au choix à la vapeur, à l'étouffée ou
                            à l'eau.
                          </li>
                          <li>
                            Pendant ce temps : préparer le POTAGE (2 portions) : Cuire les verts coupés avec 2 carottes
                            en grosses rondelles, 1 pomme de terre en dès (3 doses de potage) et 1 cube de bouillon de
                            légumes. Mixer et résever au frais ou congeler dans un récipient hermétique ou recouvert de
                            film étirable.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="prep-block">
                        <div className="prep-head">
                          <h4>3. Le riz</h4>
                          <img src="images/bcm-riz.jpg" alt="riz" className="img-fluid" />
                        </div>

                        <ul>
                          <li>Cuire 60 g poids sec de riz dans un grand volume d'eau bouillante salée.</li>
                          <li>
                            Egoutter, rincer et stocker au réfrigérateur dans un récipient hermétique ou recouvert de
                            film étirable (2 portions)
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="prep-block">
                        <div className="prep-head">
                          <h4>4. La farce maison légère</h4>
                          <img src="images/bcm-farce.jpg" alt="farce" className="img-fluid" />
                        </div>

                        <ul>
                          <li>
                            Éplucher et émincer un oignon. Le faire dorer dans une poêle à revêtement antiadhésif sans
                            matières grasses. Ajouter la viande haché à 5% de MG émiettée (125 g) et poursuivre la
                            cuisson. Laisser tiédir.
                          </li>
                          <li>
                            Mixer avec 2 tranches de jambon blanc decouenné dégraissé, l'oeuf et le persil. Saler et
                            poivrer.
                          </li>
                          <li>Séparer en 2 portions à utiliser pour 2 recettes différentes.</li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="prep-block">
                        <div className="prep-head">
                          <h4>5. Le chou vert</h4>
                          <img src="images/bcm-chouvert.jpg" alt="chou vert" className="img-fluid" />
                        </div>

                        <ul>
                          <li>Laver le chou vert.</li>
                          <li>
                            Conserver 2 belles feuilles entières. Les blanchir quelques minutes dans une casserole d'eau
                            bouillante salée. Laisser egoutter.
                          </li>
                          <li>
                            Pour le reste, retirer les grosses côtes, émincer et cuire sans matières grasses au choix à
                            la vapeur, à l'étouffée ou à l'eau.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="wg-recipe" id="W-Recipe">
                                <h2 className="text-center"><strong>On cuisine quoi cette semaine&nbsp;?</strong></h2>
                                <div className="text-center"><h4 className="cost-tag">Cuisine et cuisson : 1 heure environ</h4></div>

                                <div className="rsWrap">
                                    <div 
                                    // style="max-width: 100%;"
                                    
                                    className='bx-wrapper mw-100'><div class="bx-viewport" aria-live="polite" style="width: 100%; overflow: hidden; position: relative; height: 1021.24px;"><div id="RecipeSlider" style="width: 5215%; position: relative; transition-duration: 0s; transform: translate3d(-960px, 0px, 0px);">
                                        
                                        
                                        <div class="wg-recipe-block bx-clone" style="float: left; list-style: none; position: relative; width: 960px;" aria-hidden="true">
                                            <div className="row">
                                                <div className="col-lg-12 text-center">
                                                    <div className="wg-recipe-title">
                                                        <h3 className="emerald"><strong>Chou à la farce légère</strong></h3>
                                                    </div>
                                                    <div className="wg-recipe-image">
                                                        <img src="images/bcm-recipe5.jpg" alt="Recette" className="img-fluid"/>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="wg-recipe-ingredients">

                                                        <div className="inLabeler"><h4>Ingrédients</h4></div>
                                                        <p>1 portion</p>
                                                        <ul>
                                                            <li>1 oignon</li>
                                                            <li>quinoa (85 g)</li>
                                                            <li>rondelles de carottes</li>
                                                            <li>huile, sel, poivre, paprika, vinaigre balsamique, persil</li>
                                                        </ul>

                                                        <p><strong>Préparation :</strong> 10 min</p>
                                                        <p><strong>Cuisson :</strong> 5 min</p>
                                                        <p><strong>Conservation :</strong>  à congeler ou mettre au freezer</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="wg-recipe-instructions">

                                                        <div className="inLabeler"><h4>Instructions</h4></div>
                                                        <p>Pour monter vos ballotins, utiliser 2 ramequins. Les tapisser avec les feuilles de chou blanchies en laissant dépasser pour refermer le ballotin.</p>
                                                        <p>Remplir avec la farce légère, puis refermer le ballotin.</p>
                                                        <p>Enfourner environ 20 minutes.</p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>



                                        <div class="wg-recipe-block" style="float: left; list-style: none; position: relative; width: 960px;" aria-hidden="false">
                                            <div className="row">
                                                <div className="col-lg-12 text-center">
                                                    <div className="wg-recipe-title">
                                                        <h3 className="emerald"><strong>Quinoa aux oignons et sa fondue de carottes aigre-douce</strong></h3>
                                                    </div>
                                                    <div className="wg-recipe-image">
                                                        <img src="images/bcm-recipe1.jpg" alt="Recette" className="img-fluid"/>

                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="wg-recipe-ingredients">
                                                        <div className="inLabeler"><h4>Ingrédients</h4></div>
                                                        <p>1 portion</p>
                                                        <ul>
                                                            <li>1 oignon</li>
                                                            <li>quinoa (85 g)</li>
                                                            <li>rondelles de carottes</li>
                                                            <li>huile, sel, poivre, paprika, vinaigre balsamique, persil</li>
                                                        </ul>

                                                        <p><strong>Préparation :</strong> 10 min</p>
                                                        <p><strong>Cuisson :</strong> 5 min</p>
                                                        <p><strong>Conservation :</strong> au réfrigérateur dans un récipient hermétique ou recouvert de film étirable</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="wg-recipe-instructions">
                                                        <div className="inLabeler"><h4>Instructions</h4></div>

                                                        <p>Éplucher et émincer 1 oignon. Le faire revenir dans 1 cuillerée à café huile, puis ajouter 85 g de quinoa sec (100 g poids sec à 1600 et 1800 kcal)</p>
                                                        <p>Remuer jusqu'à ce qu'il devienne translucide. </p>
                                                        <p>Mouiller avec de l'eau (1.5 fois le volume de quinoa), saler, poivrer et laisser cuire 10 minutes à feu moyen. Retirer du feu, couvrir et laisser gonfler 5 minutes.</p>
                                                        <p>Pendant ce temps, réchauffer dans une poêle les rondelles de carottes cuites (250 g environ) avec le paprika et le vinaigre balsamique.</p>
                                                        <p>Dresser sur le quinoa recouvert de la fondue de carottes aigre-douce.</p>
                                                        <p>Facultatif : décorer de persil frais ou de coriandre.</p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        <div class="wg-recipe-block" style="float: left; list-style: none; position: relative; width: 960px;" aria-hidden="true">
                                            <div className="row">
                                                <div className="col-lg-12 text-center">
                                                    <div className="wg-recipe-title">
                                                        <h3 className="emerald"><strong>Carottes au yaourt pour 1 portion</strong></h3>
                                                    </div>
                                                    <div className="wg-recipe-image">
                                                        <img src="images/bcm-recipe2.jpg" alt="Recette" className="img-fluid"/>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="wg-recipe-ingredients">

                                                        <div className="inLabeler"><h4>Ingrédients</h4></div>
                                                        <p>1 portion</p>
                                                        <ul>
                                                            <li>1 oignon</li>
                                                            <li>quinoa (85 g)</li>
                                                            <li>rondelles de carottes</li>
                                                            <li>huile, sel, poivre, paprika, vinaigre balsamique, persil</li>
                                                        </ul>

                                                        <p><strong>Préparation :</strong> 10 min</p>
                                                        <p><strong>Cuisson :</strong> 5 min</p>
                                                        <p><strong>Conservation :</strong> au réfrigérateur dans un récipient hermétique ou recouvert de film étirable</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="wg-recipe-instructions">

                                                        <div className="inLabeler"><h4>Instructions</h4></div>
                                                        <p>Éplucher et écraser la gousse d'ail.</p>
                                                        <p>Mixer un yaourt nature 0% de MG (nature classique non sucré à 1600 et 1800 kcal) avec avec la gousse d'ail. Saler et poivrer.</p>
                                                        <p>Mélanger avec 200 à 250 g de carottes râpées.</p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>


                                        <div class="wg-recipe-block" style="float: left; list-style: none; position: relative; width: 960px;" aria-hidden="true">
                                            <div className="row">
                                                <div className="col-lg-12 text-center">
                                                    <div className="wg-recipe-title">
                                                        <h3 className="emerald"><strong> Filet mignon aux carottes (portions à réchauffer) et à la badiane</strong> </h3>
                                                    </div>
                                                    <div className="wg-recipe-image">
                                                        <img src="images/bcm-recipe3.jpg" alt="Recette" className="img-fluid"/>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="wg-recipe-ingredients">

                                                        <div className="inLabeler"><h4>Ingrédients</h4></div>
                                                        <p>1 portion</p>
                                                        <ul>
                                                            <li>1 oignon</li>
                                                            <li>quinoa (85 g)</li>
                                                            <li>rondelles de carottes</li>
                                                            <li>huile, sel, poivre, paprika, vinaigre balsamique, persil</li>
                                                        </ul>

                                                        <p><strong>Préparation :</strong> 10 min</p>
                                                        <p><strong>Cuisson :</strong> 5 min</p>
                                                        <p><strong>Conservation :</strong> au réfrigérateur dans un récipient hermétique ou recouvert de film étirable</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="wg-recipe-instructions">

                                                        <div className="inLabeler"><h4>Instructions</h4></div>
                                                        <p>Préchauffer le four à 200°C (Th 6-7).</p>
                                                        <p>Dresser le filet mignon (450 g environ) dans un plat allant au four avec un fond d'eau. Vous pouvez le badigeonner de moutarde et ajouter la garniture aromatique au choix. </p>
                                                        <p>Enfourner 15 minutes.</p>
                                                        <p>Ajouter 250 g de carottes, la badiane et un peu d'eau si besoin et poursuivre la cuisson 15 minutes.</p>
                                                        <p>Prélever et trancher 250 g de filet mignon qui sera consommé froid dans la semaine. </p>
                                                        <p>Conserver le reste avec les carottes à la badiane.</p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>


                                        <div class="wg-recipe-block" style="float: left; list-style: none; position: relative; width: 960px;" aria-hidden="true">
                                            <div className="row">
                                                <div className="col-lg-12 text-center">
                                                    <div className="wg-recipe-title">
                                                        <h3 className="emerald"><strong>Hachis parmentier diététique</strong></h3>
                                                    </div>
                                                    <div className="wg-recipe-image">
                                                        <img src="images/bcm-recipe4.jpg" alt="Recette" className="img-fluid"/>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="wg-recipe-ingredients">

                                                        <div className="inLabeler"><h4>Ingrédients</h4></div>
                                                        <p>1 portion</p>
                                                        <ul>
                                                            <li>pommes de terre (125 g)</li>
                                                            <li>purée de brocolis (200 g)</li>
                                                            <li>farce légère (la moitié)</li>
                                                            <li>fromage (20 g)</li>
                                                            <li>sel, poivre, muscade</li>
                                                        </ul>

                                                        <p><strong>Préparation :</strong> 10 min</p>
                                                        <p><strong>Cuisson :</strong> 5 min</p>
                                                        <p><strong>Conservation :</strong> à congeler ou mettre au freezer</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="wg-recipe-instructions">

                                                        <div className="inLabeler"><h4>Instructions</h4></div>
                                                        <p>Ecraser 125 g de pommes de terre au moulin à légumes ou à défaut à la fourchette.</p>
                                                        <p>Décongeler 200 g de purée de brocolis au micro-ondes, la mélanger aux pommes de terre, ajouter la muscade et le fromage au choix (20 à 25 g) en morceaux ou râpé, le sel, le poivre.</p>
                                                        <p>Dans un plat à gratin, déposer une couche de purée, une couche de farce légère (la moitie de la dose préparée), puis la couche de purée.</p>
                                                        <p>Enfourner environ 20 minutes</p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>


                                        <div class="wg-recipe-block" style="float: left; list-style: none; position: relative; width: 960px;" aria-hidden="true">
                                            <div className="row">
                                                <div className="col-lg-12 text-center">
                                                    <div className="wg-recipe-title">
                                                        <h3 className="emerald"><strong>Chou à la farce légère</strong></h3>
                                                    </div>
                                                    <div className="wg-recipe-image">
                                                        <img src="images/bcm-recipe5.jpg" alt="Recette" className="img-fluid"/>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="wg-recipe-ingredients">

                                                        <div className="inLabeler"><h4>Ingrédients</h4></div>
                                                        <p>1 portion</p>
                                                        <ul>
                                                            <li>1 oignon</li>
                                                            <li>quinoa (85 g)</li>
                                                            <li>rondelles de carottes</li>
                                                            <li>huile, sel, poivre, paprika, vinaigre balsamique, persil</li>
                                                        </ul>

                                                        <p><strong>Préparation :</strong> 10 min</p>
                                                        <p><strong>Cuisson :</strong> 5 min</p>
                                                        <p><strong>Conservation :</strong>  à congeler ou mettre au freezer</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="wg-recipe-instructions">

                                                        <div className="inLabeler"><h4>Instructions</h4></div>
                                                        <p>Pour monter vos ballotins, utiliser 2 ramequins. Les tapisser avec les feuilles de chou blanchies en laissant dépasser pour refermer le ballotin.</p>
                                                        <p>Remplir avec la farce légère, puis refermer le ballotin.</p>
                                                        <p>Enfourner environ 20 minutes.</p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                    <div class="wg-recipe-block bx-clone" style="float: left; list-style: none; position: relative; width: 960px;" aria-hidden="true">
                                            <div className="row">
                                                <div className="col-lg-12 text-center">
                                                    <div className="wg-recipe-title">
                                                        <h3 className="emerald"><strong>Quinoa aux oignons et sa fondue de carottes aigre-douce</strong></h3>
                                                    </div>
                                                    <div className="wg-recipe-image">
                                                        <img src="images/bcm-recipe1.jpg" alt="Recette" className="img-fluid"/>

                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="wg-recipe-ingredients">
                                                        <div className="inLabeler"><h4>Ingrédients</h4></div>
                                                        <p>1 portion</p>
                                                        <ul>
                                                            <li>1 oignon</li>
                                                            <li>quinoa (85 g)</li>
                                                            <li>rondelles de carottes</li>
                                                            <li>huile, sel, poivre, paprika, vinaigre balsamique, persil</li>
                                                        </ul>

                                                        <p><strong>Préparation :</strong> 10 min</p>
                                                        <p><strong>Cuisson :</strong> 5 min</p>
                                                        <p><strong>Conservation :</strong> au réfrigérateur dans un récipient hermétique ou recouvert de film étirable</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="wg-recipe-instructions">
                                                        <div className="inLabeler"><h4>Instructions</h4></div>

                                                        <p>Éplucher et émincer 1 oignon. Le faire revenir dans 1 cuillerée à café huile, puis ajouter 85 g de quinoa sec (100 g poids sec à 1600 et 1800 kcal)</p>
                                                        <p>Remuer jusqu'à ce qu'il devienne translucide. </p>
                                                        <p>Mouiller avec de l'eau (1.5 fois le volume de quinoa), saler, poivrer et laisser cuire 10 minutes à feu moyen. Retirer du feu, couvrir et laisser gonfler 5 minutes.</p>
                                                        <p>Pendant ce temps, réchauffer dans une poêle les rondelles de carottes cuites (250 g environ) avec le paprika et le vinaigre balsamique.</p>
                                                        <p>Dresser sur le quinoa recouvert de la fondue de carottes aigre-douce.</p>
                                                        <p>Facultatif : décorer de persil frais ou de coriandre.</p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div></div></div><div className="bx-controls bx-has-controls-direction bx-has-pager"><div className="bx-controls-direction"><a className="bx-prev" href="">Prev</a><a className="bx-next" href="">Next</a></div><div className="bx-pager bx-default-pager"><div className="bx-pager-item"><a href="" data-slide-index="0" className="bx-pager-link active">1</a></div><div className="bx-pager-item"><a href="" data-slide-index="1" className="bx-pager-link">2</a></div><div className="bx-pager-item"><a href="" data-slide-index="2" className="bx-pager-link">3</a></div><div className="bx-pager-item"><a href="" data-slide-index="3" className="bx-pager-link">4</a></div><div className="bx-pager-item"><a href="" data-slide-index="4" className="bx-pager-link">5</a></div></div></div></div>
                                </div>
                            </div> */}

                {/* <link rel="stylesheet" href="https://cdn.jsdelivr.net/bxslider/4.2.12/jquery.bxslider.css">
                            <script src="https://cdn.jsdelivr.net/bxslider/4.2.12/jquery.bxslider.min.js"></script>

                            <script>
                                $(function () {
                                    var recipeSlider = $('#RecipeSlider').bxSlider({
                                        infiniteLoop: true,
                                        touchEnabled: true,
                                        minSlides: 1,
                                        maxSlides: 1,
                                        
                                        responsive: true,
                                        adaptiveHeight: true,
                                        onSlideAfter: function () {
                                           
                                            $('html, body').animate({
                                                scrollTop: $('#RecipeSlider').offset().top
                                            }, 300);
                                        }
                                    });

                                     
                                });
                            </script>
                              */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeeklyGuide;
