import { useState } from "react";
import { Carousel, CarouselControl, CarouselIndicators, CarouselItem } from "reactstrap";
import styled from "styled-components";

const items = [
  {
    src: "/images/bcm-testimonials01.png",
    altText: "Juliette",
    header: "Tous les soirs, c’est déjà prêt… Le rêve !",
    description:
      " Je n’ai plus besoin de réfléchir à ce que je vais préparer. Je n’ai plus besoin de cuisiner vite-fait alors que je suis éreintée par ma journée. Il suffit de sortir les plats du frigo et de les réchauffer… À table&nbsp;!",
    subtitle: "Juliette (Beauvais)",
  },
  {
    src: "/images/bcm-testimonials02.png",
    altText: "Quentin",
    header: "Le Batch Cooking, le bon plan quand on est étudiant",
    description:
      " Le lendemain des courses, je passe aux fourneaux ! En 2 heures (temps de cuissons compris), je me fais 5 préparations en suivant les recettes. J’ai trouvé ça tellement bon, tellement malin, et tellement pratique que le Batch Cooking est devenu une habitude, un rai mode de vie...",
    subtitle: "Quentin (Saclay)",
  },
  {
    src: "/images/bcm-testimonials03.png",
    altText: "Eve",
    header: "C’est mieux que de se faire livrer à la maison !",
    description:
      "Avec les box livrées à domicile, on perd du poids, sauf que ça coûte cher et qu’il faut pouvoir tenir financièrement… Et dès qu'on s’arrête c’est l'hécatombe : on ne sait plus comment manger raisonnablement et, au final, on reprend plus de poids que ce qu’on a perdu. Je préfère apprendre à bien manger une fois pour toutes&nbsp;!",
    subtitle: "Eve (Nantes)",
  },
];

export const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const itemLength = items.length - 1;

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? itemLength : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === itemLength ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const carouselItemData = items.map((item) => {
    return (
      <CarouselItem key={item.src} onExited={() => setAnimating(false)} onExiting={() => setAnimating(true)}>
        <div className="bcm-testimonial-carousel">
          <div className="bcm-tm-img">
            <img src={item.src} alt={item.altText} className="img-fluid mx-auto" />
          </div>
          <div>
            <h5>{item.header}</h5>
            <p className="bcm-tm-description">{item.description}</p>
            <div className="bcm-tm-name">{item.subtitle}</div>
          </div>
        </div>
      </CarouselItem>
    );
  });

  return (
    <div className="container-fluid">
      <h2 className="salmon text-center">
        <strong>Pourquoi ils en raffolent&nbsp;!</strong>
      </h2>

      <div className="testimonial-limiter">
        <Carousel previous={previous} next={next} activeIndex={activeIndex}>
          <StyledCarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={(newIndex: number) => {
              if (animating) return;
              setActiveIndex(newIndex);
            }}
          />
          {carouselItemData}
          <CarouselControl directionText=" " direction="prev" onClickHandler={previous} />
          <CarouselControl directionText=" " direction="next" onClickHandler={next} />
        </Carousel>
      </div>
    </div>
  );
};

const StyledCarouselIndicators = styled(CarouselIndicators)`
  margin-bottom: 15px;

  button {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
  }
`;
