// @flow
import CommencerCard from "../shared/CommencerCard";
import VosQuestions from "../shared/VosQuestions";

const DrCohen = () => {
  return (
    <section className="level2-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="bcm-leftcol-wrap">
              <h1 className="emerald">
                <strong>Je suis le Dr Jean-Michel Cohen</strong>
              </h1>
              <p>
                <img src="images/bcm-content-dr-cohen.jpg" alt="Dr Jean-Michel Cohen" className="img-fluid" />
              </p>

              <p>Bonjour et heureux de vous rencontrer... </p>
              <p>
                Vous m’avez peut-être déjà vu à la télévision ou sur les vidéos que je poste sur YouTube. Je tiens à me
                présenter...
              </p>
              <p>
                Je suis le Dr Jean-Michel Cohen, médecin-nutritionniste, marié, père et désormais grand-père. Depuis
                1981, je me consacre presque exclusivement aux relations entre l’humain et l’assimilation des aliments.{" "}
              </p>
              <p>
                Après avoir rédigé ma thèse en 1983 à Paris VII sous le titre “Les obésités post-grossesse et
                l'hyperplasie du tissu adipeux”, je suis le premier à avoir introduit en France l'utilisation de
                solutions de perfusion à base de triglycérides à chaînes moyennes pour l'alimentation artificielle en
                réanimation.
              </p>
              <p>
                Auteur et YouTubeur très actif, je propose sur internet, dès la fin des années 2000, un programme
                minceur personnalisé en ligne avec suivi diététique et plans repas sur mesure. Plus de 700.000 personnes
                ont suivi ce programme à ce jour.{" "}
              </p>
              <p>
                Je suis aussi présent sur les réseaux sociaux, notamment avec ma chaîne YouTube, qui compte plus de
                600.000 abonnés, et mon compte Instagram qui me vaut plus de 100.000 followers. Pas mal pour des
                conseils axés exclusivement sur la nutrition !
              </p>
              <p>
                Je suis également auteur d’ouvrages sur la nutrition et la perte de poids tels que “Savoir Maigrir” et
                plus récemment “La Méthode Cohen”. J’interviens souvent dans les médias pour partager ma vision de la
                nutrition et du marché de l’alimentation.
              </p>

              <br />
              <h2 className="salmon">
                <strong>Pourquoi le Batch-Cooking Minceur ?</strong>
              </h2>
              <p>
                Aujourd’hui, avec le Batch Cooking Minceur, je vous propose un programme{" "}
                <strong>facile et pratique</strong> pour gagner du temps dans la préparation de vos repas, manger à{" "}
                <strong>petits prix</strong>, de saison, de façon variée, équilibrée et, pour celles et ceux qui le
                souhaitent, <strong>perdre du poids</strong>.
              </p>
              <p>
                J’ai mis en place cette formule pour répondre aux difficultés de notre quotidien renforcées ces derniers
                mois&nbsp;:{" "}
                <strong>augmentation des prix, manque de temps, raréfaction de certains aliments de base…</strong> Je
                constate depuis des années que de plus en plus de gens ont du mal à gérer leur alimentation et ne savent
                plus comment s’organiser en cuisine. Résultat : le recours à la malbouffe est renforcé !
              </p>
              <p>
                Je vous propose donc de réaliser des économies de temps et d’argent tout en{" "}
                <strong>apprenant à cuisiner</strong> plus efficacement et en prenant{" "}
                <strong>soin de votre ligne</strong>. C’est toute l’ambition de ce Batch Cooking Minceur, pensé pour
                vous et votre famille.
              </p>

              <br />
              <hr />
              <br />

              <VosQuestions />
            </div>
          </div>
          <div className="col-lg-4">
            <CommencerCard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DrCohen;
