import classNames from "classnames";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DietProfilePath } from ".";
import { CurrentUser } from "../../controllers/CurrentUser";
import { GenderType } from "../../models/Types";
import DietProfileContainer from "./DietProfileContainer";

const DietProfileGender = () => {
  const [gender, setGender] = useState<GenderType | undefined>(undefined);

  const navigate = useNavigate();

  const onSelected = (value: GenderType | undefined) => {
    setGender(value);
    CurrentUser.profile.gender = value;

    setTimeout(() => {
      navigate(DietProfilePath.SelectIdeal);
    }, 500);
  };

  React.useEffect(() => {
    CurrentUser.clearProfile();
  }, []);

  return (
    <DietProfileContainer>
      <div className="firstTitle text-center">
        <h1>Perdez du poids en vous simplifiant la vie !</h1>
        <h3>+rapide +économe +autonome</h3>
        <hr />
      </div>

      <div className="dpq-wrapper">
        <div className="dpq-question   d-block" id="bcm-q1" data-progress="1">
          <h3 className="text-center emerald">
            <strong>Vous êtes&nbsp;:</strong>
          </h3>
          <div className="text-center mx-auto" style={{ maxWidth: "500px" }}>
            <img src="/dietprofile-images/bcm-dp-gender.jpg" alt="" className="img-fluid mx-auto" />
          </div>
          <div className="genderSelect text-center">
            <label
              htmlFor="q1-1"
              onClick={() => {
                onSelected(0);
              }}
              className={classNames({ selected: gender === 0 })}
            >
              Une Femme
            </label>
            &nbsp;
            <label
              htmlFor="q1-2"
              onClick={() => {
                onSelected(1);
              }}
              className={classNames({ selected: gender === 1 })}
            >
              Un Homme
            </label>
          </div>
        </div>
      </div>
    </DietProfileContainer>
  );
};

export default DietProfileGender;
