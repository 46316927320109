// @flow

import { Link } from "react-router-dom";

const CommencerCard = () => {
  return (
    <div className="rcol-floating-well">
      <div>
        <img src="/images/bcm-sample-banner.jpg" alt="Batch Cooking Minceur COHEN" className="img-fluid" />
      </div>
      <div className="p-3">
        <h2 className="cursive salmon text-center">Batch Cooking Minceur</h2>
        <p>Cuisinez vous-même en 2 heures par semaine les bons petits plats qui vont vous faire maigrir. </p>
        <ul style={{ width: "230px", margin: "15px auto" }} className="p-0 list-unstyled">
          <li className="mb-1">
            <div>
              <strong style={{ fontSize: "1.5rem" }}>
                <i className="fal fa-check salmon"></i>
              </strong>
              Perdez vos kilos en trop
            </div>
          </li>
          <li className="mb-1">
            <div>
              <strong style={{ fontSize: "1.5rem" }}>
                <i className="fal fa-check salmon"></i>
              </strong>{" "}
              Mangez équilibré
            </div>{" "}
          </li>
          <li className="mb-1">
            <div>
              <strong style={{ fontSize: "1.5rem" }}>
                <i className="fal fa-check salmon"></i>
              </strong>{" "}
              Faites des économies
            </div>
          </li>
          <li className="mb-1">
            <div>
              <strong style={{ fontSize: "1.5rem" }}>
                <i className="fal fa-check salmon"></i>
              </strong>{" "}
              Gagnez du temps
            </div>
          </li>
          <li className="mb-1">
            <div>
              <strong style={{ fontSize: "1.5rem" }}>
                <i className="fal fa-check salmon"></i>
              </strong>{" "}
              Evitez le gaspillage
            </div>
          </li>
          <li className="mb-1">
            <div>
              <strong style={{ fontSize: "1.5rem" }}>
                <i className="fal fa-check salmon"></i>
              </strong>{" "}
              Devenez autonome
            </div>
          </li>
        </ul>
        <p className="text-center">
          <Link to="/cooking-minceur" className="cta-salmon">
            COMMENCER
          </Link>
        </p>
      </div>
    </div>
  );
};

export default CommencerCard;
