import VosQuestions from "./../shared/VosQuestions";
import CommencerCard from "../shared/CommencerCard";

const Advantages = () => {
  return (
    <section className="level2-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="bcm-leftcol-wrap">
              <h1 className="emerald">
                <strong>Les 5 avantages du Batch Cooking Minceur</strong>
              </h1>
              <p>
                <img
                  src="images/bcm-content-5-avantages.jpg"
                  alt="5 avantages du Batch Cooking Minceur"
                  className="img-fluid"
                />
              </p>

              <h4 className="salmon">
                <strong>1- Perdez vos kilos en trop </strong>
              </h4>
              <p>
                Avec l’organisation nécessaire au Batch Cooking, vous <strong>planifiez tous vos repas</strong>: entrée,
                plat, dessert, collations... Ainsi, vous <strong>évitez les grignotages</strong> et les repas sur le
                pouce qui se décident à la dernière minute, en ouvrant le frigo. Vous vous protégez des tentations et
                vous vous en tenez à ce qui est bon pour vous. L’objectif : retrouver votre <strong>silhouette</strong>{" "}
                et la bonne <strong>santé</strong> qui va avec.
              </p>

              <h4 className="salmon">
                <strong>2- Gagnez du temps</strong>
              </h4>
              <p>
                Combien de temps passez-vous en cuisine chaque jour ? Avec le Batch Cooking Minceur, en une session de{" "}
                <strong>2 à 3 heures</strong>, vous préparez 90 % de vos <strong>repas de la semaine</strong>. Pas
                besoin de ressortir les casseroles et les poêles à chaque repas. Il vous suffit de sortir vos
                préparations du frigo, les réchauffer et les compléter au besoin en seulement quelques minutes
                (assaisonnement, assemblage…).
              </p>

              <h4 className="salmon">
                <strong>3- Réalisez des économies</strong>
              </h4>
              <p>
                Vous allez faire des économies. Vous avez acheté un kilo de carottes dont le prix était intéressant ?
                Pas de problème : 200 à 300 g de carottes râpées pour lundi midi, des carottes Vichy pour mercredi et,
                vendredi, la purée de carottes accompagnera idéalement un bon filet de poisson. Cela évite d’acheter
                trop de produits différents, fini le gaspillage !
              </p>

              <h4 className="salmon">
                <strong>4- Mangez toujours équilibré</strong>
              </h4>
              <p>
                C’est le nutritionniste Jean-Michel Cohen qui a conçu tous les menus du Batch Cooking Minceur. Vous
                pouvez donc avoir confiance : les plans de repas sont parfaitement équilibrés sur le plan nutritionnel.
                Ainsi, vous savez, à l’avance, ce que vous allez manger et vous savez, à l’avance, que vous allez bien
                manger. Fini les préparations improvisées en fonction de ce qu’il reste dans le frigo.
              </p>

              <h4 className="salmon">
                <strong>5- Libérez-vous des contraintes quotidiennes</strong>
              </h4>
              <p>
                Pour le boulot, vous partez tôt et vous rentrez tard ? Avec le Batch Cooking Minceur, emmenez votre plat
                à réchauffer au travail et, en rentrant le soir à la maison, tout est prêt aussi, il n’y a qu’à
                réchauffer. C’est ça, l’organisation ! Que vous soyez célibataire ou en couple avec des enfants, c’est
                tout bénef !
              </p>
              <br />
              <hr />
              <br />

              <VosQuestions />
            </div>
          </div>
          <div className="col-lg-4">
            <CommencerCard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Advantages;
