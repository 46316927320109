import CommencerCard from "../shared/CommencerCard";
import VosQuestions from "../shared/VosQuestions";

const RechaufferLesServir = () => {
  return (
    <section className="level2-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="bcm-leftcol-wrap">
              <h1 className="emerald">
                <strong>Comment réchauffer les plats et les servir&nbsp;?</strong>
              </h1>
              <p>
                <img src="images/bcm-content-rechauffer-les-plats.jpg" alt="les plats" className="img-fluid" />
              </p>

              <p>
                Pour réchauffer les plats, plusieurs propositions s’offrent à vous selon vos équipements, le temps dont
                vous disposez et vos préférences.
              </p>

              <ul>
                <li>
                  <p>
                    <strong>Le four à micro-onde</strong> pendant au moins 3 minutes sera votre allié de dernière minute
                    pour un réchauffage express. Pensez alors à privilégier le réchauffage dans un récipient en verre et
                    à bien couvrir le récipient avec un couvercle spécial micro-ondes OU une assiette à l’envers OU
                    simplement en perçant le film étirable ou en entre-ouvrant le couvercle de la boîte afin d’éviter
                    tout risque de projections.{" "}
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Le four traditionnel à 80°C</strong> minimum pendant 20 minutes reste une option et pour
                    obtenir un résultat légèrement gratiné ou garder le croustillant d’une tarte, poussez le thermostat
                    à 180°C.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>La casserole au bain-marie</strong> ou directement dans un récipient à couvert conviendra
                    bien aux produits en sauce ou bouillon.
                  </p>
                </li>
              </ul>

              <p>
                <strong>Astuce</strong>&nbsp;: pour éviter que certains plats soient trop secs, il est possible de
                rajouter du bouillon sans matière grasse.
              </p>

              <br />
              <hr />
              <br />
              <VosQuestions />
            </div>
          </div>
          <div className="col-lg-4">
            <CommencerCard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default RechaufferLesServir;
