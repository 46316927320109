import { useNavigate } from "react-router-dom";
import DietProfileContainer from "./DietProfileContainer";
import { DietProfilePath } from ".";
import { useState } from "react";
import classNames from "classnames";
import { CurrentUser } from "../../controllers/CurrentUser";

const DietProfileFigure = () => {
  const [bodyshape, setBodyshape] = useState(CurrentUser.profile.q4 ?? 0);

  const navigate = useNavigate();

  const onSelect = (value: number) => {
    setBodyshape(value);
    CurrentUser.profile.q4 = value;

    setTimeout(() => {
      navigate(DietProfilePath.Exces);
    }, 500);
  };

  return (
    <DietProfileContainer>
      <div className="dpq-wrapper">
        <div className="dpq-question d-block" data-progress="4">
          <h3 className="text-center  emerald">
            <strong>Où avez-vous le plus tendance à stocker vos kilos&nbsp;?</strong>
          </h3>

          <div
            className={classNames({
              "dpq-bodyshapes": true,
              female: CurrentUser.profile.gender !== 1,
              male: CurrentUser.profile.gender === 1,
            })}
          >
            <label onClick={() => onSelect(1)} className={classNames({ selected: bodyshape === 1 })}>
              <img src="/dietprofile-images/bcm-dp-woman1.png" alt="" className="mx-auto img-fluid img-female" />
              <img src="/dietprofile-images/bcm-dp-man1.png" alt="" className="mx-auto img-fluid  img-male" />
              <span>Ventre</span>
            </label>

            <label onClick={() => onSelect(2)} className={classNames({ selected: bodyshape === 2 })}>
              <img src="/dietprofile-images/bcm-dp-woman2.png" alt="" className="mx-auto img-fluid img-female" />
              <img src="/dietprofile-images/bcm-dp-man2.png" alt="" className="mx-auto img-fluid img-male" />
              <span>Bas du corps</span>
            </label>

            <label onClick={() => onSelect(3)} className={classNames({ selected: bodyshape === 3 })}>
              <img src="/dietprofile-images/bcm-dp-woman3.png" alt="" className="mx-auto img-fluid img-female " />
              <img src="/dietprofile-images/bcm-dp-man3.png" alt="" className="mx-auto img-fluid img-male" />
              <span>Le haut du corps</span>
            </label>

            <label onClick={() => onSelect(4)} className={classNames({ selected: bodyshape === 4 })}>
              <img src="/dietprofile-images/bcm-dp-woman4.png" alt="" className="mx-auto img-fluid img-female  " />
              <img src="/dietprofile-images/bcm-dp-man4.png" alt="" className="mx-auto img-fluid  img-male" />
              <span>Tout le corps</span>
            </label>
          </div>
        </div>
      </div>
    </DietProfileContainer>
  );
};

export default DietProfileFigure;
