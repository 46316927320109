const ContactInfo = () => {
  return (
    <section className="level2-content bg-gray">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="bcm-fullWidth-wrap">
              <div className="cmContent">
                <p className="text-center">
                  <img
                    src="images/bcm-contact-menu.jpg"
                    alt="Comment souhaitez-vous nous contacter ?"
                    className="img-fluid mx-auto"
                  />
                </p>
                <h2 className="text-center">Comment souhaitez-vous nous contacter&nbsp;?</h2>
                <div className="contactMenu">
                  <div className="contactSelect">
                    <a
                      href="#non"
                      // onClick="openAnxaMessenger()"
                    >
                      <i className="fal fa-comment-lines"></i>
                      <span>Chat</span>
                    </a>
                  </div>
                  <div className="contactSelect">
                    <a href="tel: +33411880112">
                      <i className="fal fa-mobile"></i>
                      <span>Téléphone</span>
                    </a>
                  </div>
                  <div className="contactSelect">
                    <a href="sm-bcm2-contactez-nous.html" target="_blank">
                      <i className="fal fa-envelope"></i>
                      <span>Email</span>
                    </a>
                  </div>
                  {/* <!--<div className="contactSelect d-block d-md-none">
                                    <a href="https://wa.me/message/EFN33UMSIYPSC1" target="_blank">
                                        <i className="fab fa-whatsapp"></i>
                                        <span>Whatsapp</span>
                                    </a>
                                </div>--> */}
                  <div className="contactSelect">
                    <a
                      href="https://calendly.com/teamwaouw/votre-diagnostic-poids-avec-un-conseiller-minceur"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fal fa-calendar-check"></i>
                      <span>Prendre un RDV </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactInfo;
