import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Fade, Input } from "reactstrap";
import styled, { keyframes } from "styled-components";
import { DietProfilePath } from ".";
import DietProfileContainer from "./DietProfileContainer";
import { CurrentUser } from "../../controllers/CurrentUser";
import StringUtil from "../../utils/StringUtil";

interface InValidFormState {
  firstName?: string;
  lastName?: string;
  email?: string;
  mobileNumber?: string;
}

const initialFormState: InValidFormState = {};

const DietProfileLoaderForm = () => {
  const navigate = useNavigate();

  const [showForm, setShowForm] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [checkTerms, setCheckTerms] = useState(false);
  const [isFranceNumber, setIsFranceNumber] = useState(true);
  const [isForeignNumber, setIsForeignNumber] = useState(false);

  const [inValidState, setInValidState] = useState<InValidFormState>(initialFormState);

  useEffect(() => {
    setTimeout(() => {
      setShowForm(true);
    }, 9000);
  }, []);

  const onContinue = () => {
    let _state = initialFormState;
    if (!firstName) {
      _state = { ..._state, firstName: "Merci de préciser votre prénom" };
    }
    if (!lastName) {
      _state = { ..._state, lastName: "Merci de bien vouloir entrer votre nom de famille !" };
    }
    if (!email || !StringUtil.isValidEmail(email)) {
      _state = { ..._state, email: "Merci de préciser votre email" };
    }
    if (mobileNumber.length > 0 && mobileNumber.length < 10) {
      _state = {
        ..._state,
        mobileNumber: "Attention, votre numéro de téléphone ne semble pas être correct, merci de vérifier",
      };
    }

    setInValidState(_state);
    //console.log(_state);
    if (_state !== initialFormState) {
      return;
    }

    CurrentUser.profile.firstName = firstName;
    CurrentUser.profile.lastName = lastName;
    CurrentUser.profile.email = email;
    CurrentUser.profile.mobileNumber = mobileNumber;
    CurrentUser.saveProfile();

    setTimeout(() => {
      navigate(DietProfilePath.Results);
    }, 500);
  };

  return (
    <DietProfileContainer>
      {!showForm && (
        <div className="loader-wrapper" id="DPLoader">
          <div className="loading-image">
            <img src="/dietprofile-images/circle-loader.gif" alt="Loading" className="img-fluid" />
          </div>
          <div className="loading-status">
            <LoaderText className="h5 line1">Calcul de votre IMC et de votre objectif...</LoaderText>
            <LoaderText className="h5 line2">Votre profil alimentaire et physique...</LoaderText>
            <LoaderText className="h5 line3">Estimation de la durée de votre programme... </LoaderText>
          </div>
        </div>
      )}

      {showForm && (
        <Fade in={true} className="infos-personelles">
          <h3 className="text-center emerald">
            <strong>Vos résultats sont prêts et nous avons un programme pour vous&nbsp;!</strong>
          </h3>
          <div className="dpq-profileForm ">
            <div className="form-group">
              <label htmlFor="Prenom">Prénom</label>
              <Input type="text" onChange={(e) => setFirstName(e.currentTarget.value)} />
              {inValidState.firstName && <div className="bcmdp-errorMsg">{inValidState.firstName}</div>}
            </div>
            <div className="mt-3" />
            <div className="form-group">
              <label htmlFor="Nom">Nom</label>
              <Input type="text" onChange={(e) => setLastName(e.currentTarget.value)} />
              {inValidState.lastName && <div className="bcmdp-errorMsg">{inValidState.lastName}</div>}
            </div>
            <div className="mt-3" />
            <div className="form-group">
              <label htmlFor="Email">Email</label>
              <Input type="email" onChange={(e) => setEmail(e.currentTarget.value)} />
              {inValidState.email && <div className="bcmdp-errorMsg">{inValidState.email}</div>}
            </div>
            <div className="mt-3" />
            <div className="form-group">
              <label htmlFor="Telephone">Numéro de téléphone</label>
              <Input
                type="tel"
                value={mobileNumber}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  if (StringUtil.isNumeric(value) && value.length < 15) {
                    setMobileNumber(value);

                    if (
                      value.length === 10 &&
                      value.startsWith("0") &&
                      !value.endsWith("01010101") &&
                      !value.endsWith("0000000")
                    ) {
                      setIsFranceNumber(true);
                    } else if (value.length > 9) {
                      setIsFranceNumber(false);
                    }
                  }
                }}
              />
              {inValidState.mobileNumber && <div className="bcmdp-errorMsg">{inValidState.mobileNumber}</div>}
              {isFranceNumber && (
                <div className="smallLabel">Pour vous joindre, en cas de besoin, au sujet de ce bilan</div>
              )}

              {!isFranceNumber && (
                <div className="row center" id="noneFrenchNumber">
                  <div className="pl-4 pt-2">
                    <label htmlFor="tele_check">
                      <input
                        type="checkbox"
                        className="profil-test"
                        onClick={(e) => setIsForeignNumber(e.currentTarget.checked)}
                      />
                      <span className="smalltext-df"> Cochez pour un numéro étranger</span>
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-3" />
            <div className="form-check ">
              <input
                type="checkbox"
                className="form-check-input"
                id="Accept"
                onClick={(e) => setCheckTerms(e.currentTarget.checked)}
              />
              <label className="form-check-label accept" htmlFor="Accept">
                J'accepte de recevoir gratuitement mon rapport d'Analyse et les conseils personnalisés du Dr Cohen.
                Mon&nbsp;adresse Email restera une information confidentielle.
              </label>
            </div>
          </div>
          <div className="text-center">
            <button type="button" className="cta-salmon" onClick={onContinue} disabled={!checkTerms}>
              Continuer
            </button>
          </div>
        </Fade>
      )}
    </DietProfileContainer>
  );
};

export default DietProfileLoaderForm;

const FadeInOut = keyframes`
    0% {
       opacity: 0.0;
    }
    15% {
       opacity: 1.0;
    }   
    85%{
       opacity: 1.0;
    }
    100%{
        opacity: 0.0;
    }
`;

const LoaderText = styled.div`
  opacity: 0;
  animation: ${FadeInOut} 3s linear;
  position: absolute;
  width: 100%;

  &.line1 {
    animation-delay: 1s;
  }
  &.line2 {
    animation-delay: 4s;
  }
  &.line3 {
    animation-delay: 7s;
  }
`;
