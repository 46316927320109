import CommencerCard from "../shared/CommencerCard";
import VosQuestions from "../shared/VosQuestions";

const BatchCooking = () => {
  return (
    <section className="level2-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="bcm-leftcol-wrap">
              <h1 className="emerald">
                <strong>Le batch Cooking Minceur Dr&nbsp;Cohen, c'est quoi&nbsp;?</strong>
              </h1>
              <p>
                <img src="images/bcm-content-bcm-cest-quoi.jpg" alt="Cest quoi" className="img-fluid" />
              </p>

              <p>
                Le Batch Cooking, c’est tout simplement le fait de préparer ses repas en avance, en une seule fois, pour
                les trois jours suivants ou bien pour la semaine entière. C’est une méthode qui vous permet, avec un peu
                d’organisation, de manger varié et équilibré, à petits prix et de diminuer votre charge mentale&nbsp;!
              </p>
              <p>
                En une seule session, avec nous, vous cuisinerez en justes quantités vos plats pour les jours à venir et
                les préparations nécessaires à vos plans de repas : vous <strong>gagnez du temps</strong>, et il n’y a{" "}
                <strong>aucun gaspillage</strong>.
              </p>
              <p>
                Si le Batch Cooking demande un peu d’<strong>organisation</strong>, il évite le fameux{" "}
                <em>“Qu’est-ce qu’on mange ce soir ?”</em>, qui se traduit souvent par des repas faits à la hâte
                n’optimisant ni le contenu de votre réfrigérateur et de vos placards, ni votre budget.
              </p>

              <p>
                Avec le Batch Cooking Minceur, vos <strong>plans de repas</strong> sont{" "}
                <strong>établis en avance</strong>. Vous n’avez plus qu’à faire <strong>vos courses</strong> en suivant{" "}
                <strong>la liste préétablie</strong>, puis à vous mettre en cuisine{" "}
                <strong>1 seule fois dans la semaine</strong>&nbsp;: cette session de 2 à 3 heures vous suffira pour
                préparer vos plats et recettes <strong>des 7 prochains jours</strong>.{" "}
              </p>
              <p>
                Moins de temps passé à réfléchir à vos menus ou à cuisiner chaque jour, moins de vaisselle au quotidien,
                ce qui assurera un véritable gain de temps et d'énergie !
              </p>
              <p>
                Vos plats cuisinés seront conservés au réfrigérateur et/ou congélateur jusqu’au jour de leur
                consommation ; le jour j, il vous suffira de les réchauffer et au besoin, finaliser certaines
                préparations (assembler légumes et féculents, cuire des œufs…).
              </p>
              <p>
                Vous souhaitez perdre du poids, en mangeant équilibré et en contrôlant votre budget, sans pour autant
                passer des heures en cuisine ? C’est sûr, le <strong>Batch Cooking</strong> Minceur est fait pour vous !
              </p>

              <br />
              <hr />
              <br />
              <VosQuestions />
            </div>
          </div>
          <div className="col-lg-4">
            <CommencerCard />
          </div>
        </div>
      </div>
    </section>
  );
};

{
  /* <section className="level2-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="bcm-leftcol-wrap">
              <h1 className="emerald">
                <strong>Le batch Cooking Minceur Dr&nbsp;Cohen, c'est quoi&nbsp;?</strong>
              </h1>
              <p>
                <img src="images/bcm-content-bcm-cest-quoi.jpg" alt="Cest quoi" className="img-fluid" />
              </p>

              <p>
                Le Batch Cooking, c’est tout simplement le fait de préparer ses repas à l’avance, en une seule fois pour
                les trois jours qui viennent ou bien pour toute la semaine. C’est une méthode qui vous permet, avec un
                peu de temps pour cuisiner, de manger varié et équilibré, à petits prix.
              </p>
              <p>
                En une seule session, vous préparez vos plats pour les jours à venir et les préparations nécessaires à
                vos plans de repas : vous <strong>gagnez du temps</strong>, et il n’y a{" "}
                <strong>aucun gaspillage</strong>.
              </p>

              <p>
                Si le Batch Cooking demande un peu d’<strong>organisation</strong>, il évite le fameux “
                <em>Qu’est-ce qu’on mange ce soir ?</em>”, qui se traduit souvent par une alimentation faite à la hâte
                et qui n’optimise, ni le contenu de votre frigo et de vos placards, ni votre budget.
              </p>

              <p>
                Le Batch Cooking demande à s’organiser de la sorte : <strong>préparer à l’avance</strong> (sur 1
                semaine) vos menus et <strong>plans de repas</strong> et la <strong>liste de courses</strong> qui va
                avec. Une fois cela réalisé, et les courses faites, vous passerez{" "}
                <strong>deux ou trois heures en cuisine</strong> pour préparer vos plats et recettes avec tout ce que
                vous avez acheté.
              </p>

              <p>
                Il faut ensuite conserver vos plats cuisinés, les sortir le jour prévu et au besoin, finaliser certaines
                préparations (compléter un fond de tarte avec les ingrédients préparés…).
              </p>

              <p>
                Vous souhaitez perdre du poids en mangeant équilibré et en contrôlant votre budget, sans pour autant
                passer des heures en cuisine ? C’est sûr, le <strong>batch Cooking</strong> Minceur est fait pour vous !
              </p>
              <br />
              <hr />
              <br />
              <VosQuestions />
            </div>
          </div>
          <div className="col-lg-4">
            <CommencerCard />
          </div>
        </div>
      </div>
    </section> */
}

export default BatchCooking;
