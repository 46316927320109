import UserProfile from "../models/UserProfile";
import DietUtil from "../utils/DietUtil";

export class CurrentUser {

     //making this public so to have direct access to data
    public static profile: UserProfile;  

    public static initialize () {
       console.log('DietProfile initialize()')
       try {
            const savedData = localStorage.getItem('userData');
            if (savedData) {
                this.profile = JSON.parse(savedData);
                return true;
            }
            this.profile = {};
    
        } catch {
            return false;
        }
    }

    public static saveProfile() {
        localStorage.setItem('userData', JSON.stringify(this.profile));
    }

    public static clearProfile() {
        this.profile = {};
    }

    public static get hasDietProfileResults() {
        if (this.profile.firstName && this.profile.lastName && this.profile.gender !== undefined && this.profile.initialWeight && this.profile.height && this.profile.targetWeight)
            return true;
        return false;
    }



    public static get targetWeightloss() {
        if (this.profile.initialWeight && this.profile.targetWeight) {
            return this.profile.initialWeight - this.profile.targetWeight;
        }
        return 10;
    }

    //in months
    public static get weightlossDuration() {
        return Math.floor(this.targetWeightloss / 4 + 0.6);
       
    }

    public static get BMI() {
        return DietUtil.BMI(this.profile.height ?? 0, this.profile.initialWeight ?? 0);
    }

    public static get genderName() {
         return this.profile.gender === 1 ? 'male' : 'female'
    }

}

