export enum DietProfilePath {
    Start = '/cooking-minceur',
    SelectIdeal = '/cooking-minceur/ideal',
    UserDetails = '/cooking-minceur/details',
    DietGraph =  '/cooking-minceur/graph',
    Figure = '/cooking-minceur/figure',
    Exces = '/cooking-minceur/exces',
    Activity = '/cooking-minceur/activite',
    Quiz = '/cooking-minceur/quiz',
    Connaissez = '/cooking-minceur/connaissez',
    Equipement = '/cooking-minceur/equipement',
    Preparer = '/cooking-minceur/preparer',
    Poids = '/cooking-minceur/poids',
    LoaderForm = '/cooking-minceur/loading',
    Results = '/cooking-minceur/results',
}