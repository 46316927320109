import styled from "styled-components";

interface Props {
  initialWeight: number;
  targetWeight: number;
}

const DietGraphSVG = (props: Props) => {
  return (
    <Container>
      <SVGContent viewBox="0 0 600 500">
        <defs>
          <marker fill="#d7493b" refY="2.5" refX="2.5" markerHeight="5" markerWidth="5" id="pointer">
            <polyline id="svg_1" points="1 1, 4 2.5, 1 4" />
          </marker>
          <linearGradient y2="0.8172" x2="0.33203" y1="0.02814" x1="0.99609" id="gradient1">
            <stop offset="0" stop-color="#ff5656" />
            <stop offset="1" stop-color="rgba(255,255,255,0)" />
          </linearGradient>
        </defs>
        <path
          stroke="#ffffff"
          id="svg_18"
          d="m14,76c134,0 95,101 237,130c86,39 74,100 183,113l159,2l2,141l-581,2l0,-388z"
          stroke-width="0"
          fill="url(#gradient1)"
          className="gradient1"
        />
        <line
          opacity="0.3"
          stroke="#7f7f7f"
          id="svg_11"
          y2="75.5"
          x2="574.5"
          y1="74.5"
          x1="31.5"
          stroke-width="2"
          fill="none"
        />

        {/* Gray dashed line */}
        <path
          id="svg_8"
          stroke-dasharray="10 3"
          fill="none"
          stroke-width="4"
          stroke="gray"
          d="m15,76c235,4 201,221 288,220c85,2 108,-206 288,-220"
          className="path-gray"
        />
        <path
          id="svg_9"
          fill="none"
          stroke="#ffffff"
          stroke-width="5"
          d="m15,77c235,4 201,221 288,220c85,2 108,-206 288,-220"
          className="path-gray-cover"
        />
        <ellipse
          stroke="gray"
          stroke-width="7"
          ry="6.94611"
          rx="7.11149"
          id="svg_4"
          cy="74.94368"
          cx="585.03259"
          fill="white"
        />

        {/* Red line */}
        <path
          id="svg_10"
          fill="none"
          stroke="#d7493b"
          stroke-width="4"
          marker-end="url(#pointer)"
          d="m15,74c134,0 95,101 237,130c86,39 74,100 183,113l155,2"
          className="path-red"
        />
        <path
          stroke="#d7493b"
          d="m5.8,19.2l0,0c0,-3.4 4,-6.2 9,-6.2l4.1,0l0,0l19.7,0l36.9,0c2.4,0 4.7,0.6 6.4,1.8c1.7,1.2 2.6,2.7 2.6,4.4l0,15.3l0,0l0,9.2l0,0c0,3.4 -2.8,7.2 -7.8,7.2l-42.8,0.2l-4.6,8.8c-1.5,-3 -2.2,-4.8 -4,-9.1l-10.5,-0.9c-6.8,0.8 -9,-2.8 -9,-6.2l0,0l0,-9.2l0,0l0,-15.3z"
          id="svg_3"
          fill="#d7493b"
        />

        {/* initial  */}
        <text
          fill="white"
          x="17.6"
          y="40"
          id="svg_5"
          stroke-width="0"
          font-size="23"
          text-anchor="start"
          font-weight="normal"
        >
          {props.initialWeight}kg
        </text>
        <ellipse stroke="#d7493b" stroke-width="8" ry="10" rx="10" id="svg_4" cy="74" cx="18" fill="white" />

        {/* target  */}

        <g id="targetWeight">
          <ellipse
            fill="white"
            cx="413.03259"
            cy="311.94368"
            id="svg_4"
            rx="7.11149"
            ry="6.94611"
            stroke-width="7"
            stroke="#49868d"
          />
          <path
            fill="#49868d"
            id="svg_7"
            d="m377.82002,372.8l0,0c0,3.9 6.16051,7.2 13.86115,7.2l6.27052,0l0,0l30.14249,0l56.54468,0c3.74031,0 7.15059,-0.7 9.79081,-2.1c2.64022,-1.4 4.07034,-3.1 4.07034,-5.1l0,-17.8l0,0l0,-10.6l0,0c0,-3.9 -4.18035,-8.3 -11.99099,-8.3l-65.67543,-0.2l-7.04058,-10.2c-2.31019,3.5 -3.41028,5.6 -6.16051,10.6l-16.17134,1c-10.45086,-0.9 -13.86115,3.2 -13.86115,7.2l0,0l0,10.6l0,0l0,17.8l0,0l0.22002,-0.1l-0.00001,0z"
            stroke="null"
          />
          <text text-anchor="start" font-size="23" stroke-width="0" id="svg_6" y="364.79925" x="425.65888" fill="white">
            {props.targetWeight}kg
          </text>
          <path
            id="svg_5"
            d="m410.05555,347l-10.11111,0c-1.19618,0 -2.17118,0.90575 -2.12604,2.00263c0.00903,0.22021 0.01805,0.44041 0.0316,0.65646l-4.76667,0c-0.60035,0 -1.08333,0.44457 -1.08333,0.99716c0,3.84737 1.51215,6.52308 3.5434,8.33874c1.99965,1.79073 4.43715,2.69233 6.23368,3.14936c1.05625,0.27006 1.77847,1.08026 1.77847,1.8946c0,0.86836 -0.76736,1.57468 -1.71076,1.57468l-1.17812,0c-0.79896,0 -1.44445,0.59414 -1.44445,1.32955s0.64549,1.32955 1.44445,1.32955l8.66667,0c0.79896,0 1.44445,-0.59414 1.44445,-1.32955s-0.64549,-1.32955 -1.44445,-1.32955l-1.17812,0c-0.9434,0 -1.71076,-0.70632 -1.71076,-1.57468c0,-0.81435 0.71771,-1.62869 1.77847,-1.8946c1.80104,-0.45703 4.23854,-1.35863 6.23819,-3.14936c2.02674,-1.81566 3.53889,-4.49137 3.53889,-8.33874c0,-0.55259 -0.48299,-0.99716 -1.08333,-0.99716l-4.76667,0c0.01354,-0.21605 0.02257,-0.4321 0.0316,-0.65646c0.04514,-1.09688 -0.92986,-2.00263 -2.12604,-2.00263l0,0l-0.00002,0zm-15.84826,4.65341l3.80972,0c0.41076,3.7435 1.31805,6.24471 2.34271,7.91911c-1.12396,-0.45703 -2.29305,-1.10103 -3.30417,-2.00678c-1.44445,-1.29215 -2.61805,-3.15767 -2.84375,-5.91232l-0.00451,0l0,-0.00001zm18.74167,5.91232c-1.01111,0.90575 -2.18021,1.54975 -3.30417,2.00678c1.02465,-1.6744 1.93195,-4.1756 2.34271,-7.91911l3.80972,0c-0.23021,2.75465 -1.40382,4.62017 -2.84375,5.91232l-0.00451,0l0,0.00001z"
            stroke-width="3"
            fill="#f6d13a"
            stroke="null"
          />
        </g>

        <text
          fill="#000000"
          stroke="#ffffff"
          stroke-width="0"
          x="456.5"
          y="223"
          id="textPasYoyo"
          font-size="23"
          text-anchor="start"
        >
          <tspan y="223" x="460" id="svg_13" fontWeight={500}>
            Pas d'effet
          </tspan>
          <tspan y="252" x="475" id="svg_14" fontWeight={500}>
            yo-yo !
          </tspan>
        </text>
      </SVGContent>
    </Container>
  );
};

export default DietGraphSVG;

const Container = styled.div`
  margin: 10px 35px;
  @media only screen and (max-width: 600px) {
    margin: 0px 0px;
  }
`;

const SVGContent = styled.svg`
  .path-gray-cover {
    stroke-dasharray: 1400;
    stroke-dashoffset: 1400;
    animation: draw 2s linear;
  }

  @keyframes draw {
    from {
      stroke-dashoffset: 0;
    }
    to {
      stroke-dashoffset: -1400;
    }
  }

  .path-red {
    stroke-dasharray: 700;
    stroke-dashoffset: 700;
    animation: dash 2s linear forwards 1s;
  }

  .gradient1 {
    animation: show 3s linear;
    transition: opacity 2s;
  }

  #targetWeight {
    animation: show 3s linear;
  }
  #textPasYoyo {
    animation: show 3s linear;
  }

  #pointer {
    animation: show 3s linear;
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }

  @keyframes show {
    0% {
      opacity: 0;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
